<template>
    <top-banner :depthTitle="depthTitle" :imgUrl="imgUrl" :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></top-banner>
    <div class="ListSec">
      <div class="site-container">
          <h3 class="PageTitle">양육의 지혜</h3>
          <div class="ListFilter">
              <list-search @searchData="getSearchData" id="search"></list-search>
          </div>
          <list-post :pagePost="pagePost" :postType="postType" :postDomain="postDomain" :TemplatePop="TemplatePop" :videoUrl="videoUrl" @videoLink="getVideoLink" ref="youtube"></list-post>
          <list-paging :totalPageCount="totalPageCount" @firstUpdate="firstPage" @itemUpdate="itemPage" @prevUpdate="prevPage" @nextUpdate="nextPage" @lastUpdate="lastPage" :currentPage="currentPage"></list-paging>
      </div>
    </div>
  </template>
  <script>
  import {computed, reactive } from 'vue'
  import { useHead } from '@vueuse/head'
  import TopBanner from "../components/topbanner/topbanner.vue";
  import ListSearch from "../components/filtersearch/filtersearch.vue";
  import ListPost from "../components/post/post.vue";
  import ListPaging from "../components/paging/paging.vue";

  export default {
    name:'Wisdom',
    setup() {
        const siteData = reactive({
        title: `양육의 지혜 - WithYou 위드유치료교육연구소`,
        description: 'WithYou 위드유치료교육연구소 정보공유를 소개합니다.',
        canonical: 'https://withyoulove.co.kr/Information/Wisdom',
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                    {
                        name: `description`,
                        content: computed(() => siteData.description),
                    },
            ],
            link: [
                {
                    rel: `canonical`,
                    content: computed(() => siteData.canonical),
                },
            ]
        })
    },
    components: {
      TopBanner,
      ListSearch,
      ListPost,
      ListPaging
    },
    data() {
          return {
             //상단 배너 컨텐츠
              depthTitle:'정보공유',
              imgUrl:'../../images/view/topbanner/topbanner-info.png',
  
              //빵가루
              firstTitle:"정보공유",
              lastTitle:'양육의 지혜',
              breadCate:'Information',
  
              //리스트
              TemplatePop:true,
              postType:false,
              postDomain:'/Information/Wisdom/',
              pagePost:[],
              moreView:true,
  
              //리스트 세팅 정보
              apiUrl: 'https://withyoulove.co.kr:8000/api/blogList/',
              queryOptions: {
                  per_page: 1,
                  cat:7
              },
  
              //검색
              keyword:'',

              //비디오
              videoUrl:'',

              //페이징
              totalPageCount:null,
              currentPage:1
          };
      },
      mounted() {
          if(this.$route.query.keyword){
              this.keyword = this.$route.query.keyword;
              this.queryOptions.per_page = 1;
          }

          if(this.$route.query.page){
              this.page = this.$route.query.page;
              this.keyword = this.$route.query.keyword;
              this.queryOptions.per_page = this.page;
          }
          //js/common.js 호출
          this.more('paging');
      },
      unmounted() {
            document.querySelector('html').classList.remove('is-Hidden');
        },
      methods:{
        getVideoLink:function(value){
            const $target = document.getElementsByClassName('PagePost-item')[value].getElementsByClassName('PagePostItem-link')[0];
            this.videoUrl = $target.dataset.video;  
        },

        itemPage:function(index){
            this.index = index;
            this.getPaging(index);
        },
        firstPage:function(index){
            this.index = index;
            this.index = 1
            this.getPaging(index);
        },
        prevPage:function(index){
            this.index = index;
            this.index--
            this.getPaging(index);
        },
        nextPage:function(index){
            this.index = index;
            this.index++
            this.getPaging(index);
        },
        lastPage:function(index){
            this.index = index;
            this.index = this.totalPageCount
            this.getPaging(index);
        },
      }
  }
  </script>
  