<template>
    <div class="PgStaResult">  
        <div class="pure-container">
            <div class="QuestionBox">
                <div class="QuestionBox-tit">나의 기질 유형은?</div>
            </div>
            <!-- ResultCard -->
            <div class="ResultCard">
                <div class="ResultCardTitle">
                    <span class="ResultCardTitle-txt">{{resultPost.name}}</span>
                    <span class="ResultCardTitle-tit">{{resultPost.phrases}}</span>
                </div>
                <div class="ResultCardImg">
                    <img :src="resultPost.image" class="ResultCardImg-img" />
                </div>
                <div class="ResultCardTag">
                    <div class="ResultCardTag-img"><img src="/images/view/statest/loco-colorStar.png" /></div>
                    <div class="ResultCardTagList">
                        <ul class="ResultCardTagList-list">
                            <li class="ResultCardTagList-item" v-for="(item, index) in tags" :key="index"><span class="ResultCardTagList-txt">{{ item }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- .ResultCard -->
            <!-- ResultCont -->
            <div class="ResultCont">
                <div class="ResultContTitle">
                    <span class="ResultContTitle-tit">{{resultPost.description}}</span>
                </div>
                <div class="ResultContText">
                    <div class="ResultContText-inner">
                        <p class="ResultContText-tit">{{resultPost.title1}}</p>
                        <p class="ResultContText-txt">{{resultPost.content1}}</p>
                    </div>
                    <div class="ResultContText-inner">
                        <p class="ResultContText-tit">{{resultPost.title2}}</p>
                        <p class="ResultContText-txt">{{resultPost.content2}}</p>
                    </div>
                </div>
                <!-- 강점&약점-->
                <div class="ResultContBox">
                    <dl class="ResultContBox-item">
                        <dt class="ResultContBoxTitle">강점 발휘</dt>
                        <dd class="ResultContBoxText">
                            <p class="ResultContBoxText-txt">{{resultPost.strength1}}</p>
                            <p class="ResultContBoxText-txt">{{resultPost.strength2}}</p>
                        </dd>
                    </dl>
                    <dl class="ResultContBox-item">
                        <dt class="ResultContBoxTitle">약점 보완</dt>
                        <dd class="ResultContBoxText">
                            <p class="ResultContBoxText-txt">{{resultPost.weakness1}}</p>
                            <p class="ResultContBoxText-txt">{{resultPost.weakness2}}</p>
                        </dd>
                    </dl>
                </div>
                <!-- .강점&약점-->
            </div>
            <!-- .ResultCont -->
            <!-- TestSlide -->
            <div class="TestSlideAct">
                <swiper 
                    effect="fade"
                    :pagination="{
                        clickable: true,
                    }"
                    :navigation="{
                        nextEl: `.TestSlideNext`,
                        prevEl: `.TestSlidePrev`
                    }"
                    :modules="modules" 
                    @slideChange="onSlideChange"
                    class="TestSlide"
                    ref="cardSwiper"
                >
                    <div class="swiper-button-prev TestSlidePrev"><span class="TestSlidePrev-text"><span class="TestSlidePrev-sub">PREV</span>{{this.cardPrev}}</span></div>
					<div class="swiper-button-next TestSlideNext"><span class="TestSlideNext-text"><span class="TestSlideNext-sub">NEXT</span>{{this.cardNext}}</span></div>
                    <swiper-slide class="TestSlide-item" :data-cardid="card.id" v-for="(card, index) in cards" :key="index">
                        <div class="TestSlideItem">
                            <div class="TestSlideItemHeader">
                                <div class="TestSlideItemHeader-title">{{card.cat}}</div>
                                <div class="TestSlideItemHeader-sub">나의 기질 요소</div>
                            </div>
                            <div class="TestSlideItemCnt">
                                <div class="TestSlideItemCnt-text">{{card.result}}</div>
                            </div>
                            <div class="TestSlideItemFooter">
                                <div class="TestSlideItemFooter-inner">
                                    <span class="TestSlideItemFooter-keyword">{{resultPost.name}}</span>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <!-- .TestSlide -->
            <!-- btn -->
            <div class="ResultBtn">
                <p class="ResultBtn-txt">나의 기질에 대해 더 자세히 알고 싶으신가요?</p>
                <a href="tel:0314691028" class="ResultBtn-link">상담예약 031-469-1028</a>
            </div>
            <!-- .btn -->
            <!-- ShareLink -->
            <div class="ShareLink">
                <ul class="ShareLink-list">
                    <li class="ShareLink-item">
                        <a href="javascript:void(0)" class="ShareLink-link" @click="copyMe('Copied')"><img src="/images/view/statest/ico-link.svg" alt="주소 공유하기" title="링크 공유하기"><input class="ab-text" id="copyMe" :value="clipCopy" /></a>
                    </li>
                    <li class="ShareLink-item">
                        <a href="javascript:void(0)" class="ShareLink-link" @click="kakaoShare"><img src="/images/view/statest/ico-kakao.svg" alt="카카오톡으로 공유하기" title="카카오톡으로 공유하기"></a>
                    </li>
                    <li class="ShareLink-item">
                        <a href="javascript:void(0)" class="ShareLink-link" @click="facebook"><img src="/images/view/statest/ico-facebook.svg" alt="페이스북으로 공유하기" title="페이스북으로 공유하기"></a>
                    </li>
                </ul>
            </div>
            <!-- .ShareLink -->
        </div>
    </div>
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, EffectFade, Navigation } from 'swiper';
import 'swiper/css';

export default {
  name:'TestResult',
  setup() {
      const siteData = reactive({
      title: `STA 기질 검사 - WithYou 위드유치료교육연구소`,
      description: 'WithYou 위드유치료교육연구소 고객지원',
      })
      useHead({
          title: computed(() => siteData.title),
          meta: [
                  {
                      name: `description`,
                      content: computed(() => siteData.description),
                  },
          ],
      })
      function copyMe(){
        var input = document.querySelector('#copyMe');

        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        // handle iOS devices
        input.contenteditable = true;
        input.readonly = false;

        var range = document.createRange();
        range.selectNodeContents(input);

        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        input.setSelectionRange(0, 999999);
        } else {
        // other devices are easy
        input.select()
        }
        document.execCommand('copy');
        alert('주소가 복사되었습니다.');
      }

      return {
        modules: [Pagination, EffectFade, Navigation],
        copyMe
      };
  },
  components:{
    Swiper,
    SwiperSlide,
  },
  data(){
    return{
        apiUrl:'https://withyoulove.co.kr:8000/api/resultView/',

        //페이지 정보
        resultPost:[],
        tags:[],
        cards:[],
        cardPrev:'',
        cardNext:'',
        clipCopy:window.location.href,
    }
  },
  computed:{
    swiper() {
      return this.$refs.cardSwiper;
    }
  },
  mounted() {
    document.body.classList.add('no-floting');
    axios.get(this.apiUrl + this.$route.params.id)
    .then((response) => {
        this.resultPost = response.data.basic_info.select;
        this.tags = response.data.basic_info.select.tag;
        this.cards = response.data.basic_info.card;
        this.cardPrev =  response.data.basic_info.card[0].cat;
        this.cardNext =  response.data.basic_info.card[1].cat;
    })
  },
  unmounted() {
    document.body.classList.remove('no-floting');
  },
  methods:{
    kakaoShare() {
        window.Kakao.Link.sendDefault({
            objectType: 'feed',
            content:{
                title:'나의 기질 유형은?',
                description: '나의 기질 유형은?',
                imageUrl:'/TestResult/',
                link:{
                    mobileWebUrl:'https://withyoulove.co.kr/TestResult/' + this.$route.params.id,
                    webUrl:'https://withyoulove.co.kr/TestResult/' + this.$route.params.id
                }
            }
        })
    },

    facebook(){
        const pageUrl = 'https://withyoulove.co.kr/TestResult/' + this.$route.params.id;
        const facebookUrl = `http://www.facebook.com/sharer/sharer.php?u=${pageUrl}`;
        window.open(facebookUrl, 'facebook', "_blank");
    },

    //카드슬라이드 체인지
    onSlideChange(index) {
        let PrevNum = index.activeIndex - 1;
        let NextNum = index.activeIndex + 1;

        axios.get(this.apiUrl + this.$route.params.id)
        .then((response) => {
            this.cardPrev =  response.data.basic_info.card[PrevNum].cat;
            this.cardNext =  response.data.basic_info.card[NextNum].cat;
        })
    },
  }
}
</script>
