<template>
    <div class="TopBanner">
        <div class="TopBannerBg">
            <img class="TopBannerBg-img" :src="imgUrl" alt="">
        </div>
        <div class="TopBannerText">
            <div class="site-container">
                <h2 class="TopBannerText-title">{{ depthTitle }}</h2>
                <breadcrumb :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></breadcrumb>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from "../breadcrumb/breadcrumb.vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
    components: {
        Breadcrumb
    },
    props:['depthTitle', 'imgUrl','firstTitle','lastTitle','breadCate'],
    mounted() {
        ScrollTrigger.refresh(true);
        this.t = gsap.timeline({
            scrollTrigger: {
                trigger: ".site",
                start: "top top",
                end: "100%",
                scrub: true,
            }
        })
        .to(".TopBannerBg-img", { y : '50%'})
    },
    unmounted() {
        this.t.kill();
    },
    methods: {
  
    }
}
</script>