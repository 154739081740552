<template>
    <!-- TabMou -->
    <div class="TabMou">
        <div class="TabMouHeader">
            <ul class="TabMouHeader-list">
                <li class="TabMouHeader-item is-Current">
                    <a href="javascript:void(0)" class="TabMouHeader-link" @click="tabClick('one')">
                        <span class="TabMouHeader-txt"><span class="mo-hidden">함께하는 </span>교육기관</span>
                    </a>
                </li>
                <li class="TabMouHeader-item">
                    <a href="javascript:void(0)" class="TabMouHeader-link" @click="tabClick('two')">
                        <span class="TabMouHeader-txt"><span class="mo-hidden">함께하는 </span>센터</span>
                    </a>
                </li>
                <li class="TabMouHeader-item">
                    <a href="javascript:void(0)" class="TabMouHeader-link" @click="tabClick('three')">
                        <span class="TabMouHeader-txt"><span class="mo-hidden">함께하는 </span>교회</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="TabMouCont">
            <div class="TabMouCont-inner">
                <!-- item -->
                <div class="TabMouCont-item is-Current">
                    <div class="TabMouContSlide">
                        <div class="TabMouContSlide-inner">
                            <swiper
                                :slidesPerView="'auto'"
                                :navigation="true"
                                :modules="modules"
                                class="TabMouContSlide-list"
                                @swiper="onSwiper"
                            >
                            <!-- item -->
                            <swiper-slide class="TabMouContSlide-item" v-for="(slideContent, index) in slides1" :key="index"> 
                                <div class="TabMouItem">
                                    <div class="TabMouItem-img">
                                        <img :src="slideContent.thumbnail" />
                                    </div>
                                    <p class="TabMouItem-txt">{{slideContent.name}}</p>
                                </div>
                            </swiper-slide>
                            <!-- .item -->
                            </swiper>
                        </div>
                    </div>
                </div>
                <!-- .item -->
                <!-- item -->
                <div class="TabMouCont-item">
                    <div class="TabMouContSlide">
                        <div class="TabMouContSlide-inner">
                            <swiper
                                :slidesPerView="'auto'"
                                :navigation="true"
                                :modules="modules"
                                class="TabMouContSlide-list"
                                @swiper="twoSwiper"
                            >
                            <!-- item -->
                            <swiper-slide class="TabMouContSlide-item" v-for="(slideContent, index) in slides2" :key="index"> 
                                <div class="TabMouItem">
                                    <div class="TabMouItem-img">
                                        <img :src="slideContent.thumbnail" />
                                    </div>
                                    <p class="TabMouItem-txt">{{slideContent.name}}</p>
                                </div>
                            </swiper-slide>
                            <!-- .item -->
                            </swiper>
                        </div>
                    </div>
                </div>
                <!-- .item -->
                <!-- item -->
                <div class="TabMouCont-item">
                    <div class="TabMouContSlide">
                        <div class="TabMouContSlide-inner">
                            <swiper
                                :slidesPerView="'auto'"
                                :navigation="true"
                                :modules="modules"
                                class="TabMouContSlide-list"
                                @swiper="threeSwiper"
                            >
                            <!-- item -->
                            <swiper-slide class="TabMouContSlide-item" v-for="(slideContent, index) in slides3" :key="index"> 
                                <div class="TabMouItem">
                                    <div class="TabMouItem-img">
                                        <img :src="slideContent.thumbnail" />
                                    </div>
                                    <p class="TabMouItem-txt">{{slideContent.name}}</p>
                                </div>
                            </swiper-slide>
                            <!-- .item -->
                            </swiper>
                        </div>
                    </div>
                </div>
                <!-- .item -->
            </div>
        </div>
    </div>
    <!-- .TabMou -->
</template>
<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination, Navigation } from 'swiper';
  import 'swiper/css';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      return {
        modules: [Pagination, Navigation],
      };
    },
    props:['slides1','slides2','slides3'],
    methods:{
        onSwiper(swiper) {
            this.oneswiper = swiper;
        },

        twoSwiper(swiper) {
            this.twoswiper = swiper;
        },

        threeSwiper(swiper) {
            this.threeSwiper = swiper;
        },

        tabClick(state){
            let items = document.querySelectorAll('.TabMouHeader-item');
            let contItems = document.querySelectorAll('.TabMouCont-item');
            if(state == 'one'){
                this.oneswiper.slideTo(0);
                items.forEach((item , i) => {
                    i = 0
                    item.classList.remove('is-Current');
                    items[i].classList.add('is-Current');
                });

                contItems.forEach((item , i) => {
                    i = 0
                    item.classList.remove('is-Current');
                    contItems[i].classList.add('is-Current');
                });
            }
            else if(state == 'two'){
                this.twoswiper.slideTo(0);
                items.forEach((item, i) => {
                    i = 1
                    item.classList.remove('is-Current');
                    items[i].classList.add('is-Current');
                });

                contItems.forEach((item , i) => {
                    i = 1
                    item.classList.remove('is-Current');
                    contItems[i].classList.add('is-Current');
                });
            }
            else if(state == 'three'){
                this.threeSwiper.slideTo(0);
                items.forEach((item, i) => {
                    i = 2
                    item.classList.remove('is-Current');
                    items[i].classList.add('is-Current');
                });

                contItems.forEach((item , i) => {
                    i = 2
                    item.classList.remove('is-Current');
                    contItems[i].classList.add('is-Current');
                });
            }
        }
    }
  };
</script>