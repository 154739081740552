<template>
    <div class="Breadcrumb">
        <ul class="Breadcrumb-list">
            <li class="Breadcrumb-item home">
                <router-link to="/" class="Breadcrumb-link">HOME</router-link>
            </li>
            <li class="Breadcrumb-item">
                <a href="javascript:void(0)" class="Breadcrumb-link" @click="BreadClick($event)">{{firstTitle}}</a>
                <div class="BreadcrumbSub">
                    <ul class="BreadcrumbSub-list">
                        <li class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Introduction'}" class="BreadcrumbSub-link">연구소 소개</router-link>
                        </li>
                        <li class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Temperament'}" class="BreadcrumbSub-link">위드유 프로그램</router-link>
                        </li>
                        <li class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'SpecialLecture'}" class="BreadcrumbSub-link">정보공유</router-link>
                        </li>
                        <li class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Contact'}" class="BreadcrumbSub-link">고객지원</router-link>
                        </li>
                        <li class="BreadcrumbSub-item">
                            <a href="https://smartstore.naver.com/withyoulove" class="BreadcrumbSub-link" target="_blank" rel="noopener noreferrer">스토어</a>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="Breadcrumb-item">
                <a href="javascript:void(0)" class="Breadcrumb-link" @click="BreadClick($event)">{{lastTitle}}</a>
                <div class="BreadcrumbSub">
                    <ul class="BreadcrumbSub-list">
                        <li v-if="breadCate == 'About'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Introduction'}" class="BreadcrumbSub-link">소개</router-link>
                        </li>
                        <li v-if="breadCate == 'About'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Researcher'}" class="BreadcrumbSub-link">연구원</router-link>
                        </li>
                        <li v-if="breadCate == 'About'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Activity'}" class="BreadcrumbSub-link">활동</router-link>
                        </li>
                        <li v-if="breadCate == 'About'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Mou'}" class="BreadcrumbSub-link">MOU</router-link>
                        </li>
                        <li v-if="breadCate == 'Program'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Temperament'}" class="BreadcrumbSub-link">기질 평가 프로그램</router-link>
                        </li>
                        <li v-if="breadCate == 'Program'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Psychotherapy'}" class="BreadcrumbSub-link">심리치료 및 상담</router-link>
                        </li>
                        <li v-if="breadCate == 'Program'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Special'}" class="BreadcrumbSub-link">특별프로그램</router-link>
                        </li>
                        <li v-if="breadCate == 'Program'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'PrivateQualification'}" class="BreadcrumbSub-link">민간자격 프로그램</router-link>
                        </li>
                        <li v-if="breadCate == 'Program'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'WisdomOfParenting'}" class="BreadcrumbSub-link">양육의 지혜 프로그램</router-link>
                        </li>
                        <li v-if="breadCate == 'About'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Notice'}" class="BreadcrumbSub-link">위드유 소식</router-link>
                        </li>
                        <li v-if="breadCate == 'Information'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'SpecialLecture'}" class="BreadcrumbSub-link">특별강의</router-link>
                        </li>
                        <li v-if="breadCate == 'Information'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Wisdom'}" class="BreadcrumbSub-link">양육의 지혜</router-link>
                        </li>
                        <li v-if="breadCate == 'Support'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Contact'}" class="BreadcrumbSub-link">문의하기</router-link>
                        </li>
                        <li v-if="breadCate == 'Support'" class="BreadcrumbSub-item">
                            <router-link :to="{ name: 'Location'}" class="BreadcrumbSub-link">오시는길</router-link>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data(){
        return {
            
        }
    },
    props:['firstTitle','lastTitle','breadCate'],
    methods: {
        //메뉴열기/닫기
        BreadClick: function(event){
            const _target = event.target.parentNode;
            const _allTarget = document.querySelectorAll('.Breadcrumb-item');
            if(_target.classList.contains('is-View')){
                _allTarget.forEach(item => {
                    item.classList.remove('is-View')
                });
            } else{
                _allTarget.forEach(item => {
                    item.classList.remove('is-View')
                });
                _target.classList.add('is-View')
            }
        },
    },
}
</script>