<template>
    <div v-if="mainNotice.length > 0" class="NoticeList">
        <ul class="NoticeList-inner">
            <li class="NoticeList-item" :key="i" v-for="(post,i) in mainNotice">
            <div class="NoticePost">
                <router-link :to="noticeDomain + post.id" class="NoticePost-link">
                    <div class="NoticePost-cate">NEWS</div>
                    <h4 class="NoticePost-title">{{ post.title }}</h4>
                    <div class="NoticePost-date">{{ dateTime(post.created_at) }}</div>
                </router-link>
            </div>
            </li>
        </ul>
    </div>
</template>
<script>
import moment from "moment";

export default {
    props: ['mainNotice','noticeDomain'],
    methods:{
        dateTime(value) {
            return moment(value).format('YYYY.MM.DD');
        },
    }
}
</script>