<template>
    <header id="siteHeader" class="site-header" :headToggle="isBind">
      <div class="site-container">
        <div class="HeaderInfo">
            <div class="LeftAtc">
                <h1 class="Logo">
                    <router-link class="Logo-link" to="/"><span class="ab-text">WithYou 치료교육연구소</span></router-link>
                </h1>
                <button @click="HeaderMenu" type="button" class="MobileMenu">
                    <span class="MobileMenu-item"></span>
                    <span class="MobileMenu-item"></span>
                    <span class="MobileMenu-item"></span>
                    <span class="MobileMenu-close"></span>
                </button>
                <div class="HeaderNav">
                    <ul class="HeaderNav-list">
                        <li class="HeaderNav-item">
                            <a href="javascript:void(0)" class="HeaderNav-link" @click="MoNavClick($event)" >연구소 소개</a>
                            <div class="HeaderNavMenu">
                                <ul class="HeaderNavMenu-list">
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" to="/About/Introduction">소개</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Researcher'}">연구원</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Activity'}">활동</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Mou'}">MOU</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Notice'}">위드유 소식</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="HeaderNav-item">
                            <a href="javascript:void(0)" class="HeaderNav-link" @click="MoNavClick($event)" >프로그램</a>
                            <div class="HeaderNavMenu">
                                <ul class="HeaderNavMenu-list">
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Temperament'}">기질 평가 프로그램</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Psychotherapy'}">심리치료 및 상담</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Special'}">특별프로그램</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'PrivateQualification'}">민간자격 프로그램</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'WisdomOfParenting'}">양육의 지혜 프로그램</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="HeaderNav-item">
                            <a href="javascript:void(0)" class="HeaderNav-link" @click="MoNavClick($event)" >정보공유</a>
                            <div class="HeaderNavMenu">
                                <ul class="HeaderNavMenu-list">
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'SpecialLecture'}">특별강의</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Wisdom'}">양육의 지혜</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="HeaderNav-item">
                            <a href="javascript:void(0)" class="HeaderNav-link" @click="MoNavClick($event)" >고객지원</a>
                            <div class="HeaderNavMenu">
                                <ul class="HeaderNavMenu-list">
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Contact'}">문의하기</router-link>
                                    </li>
                                    <li class="HeaderNavMenu-item">
                                        <router-link class="HeaderNavMenu-link" :to="{ name: 'Location'}">오시는길</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="HeaderNav-item">
                            <a href="/#withYoucalender" class="HeaderNav-link">일정보기</a>
                        </li>
                        <li class="HeaderNav-item">
                            <a href="https://smartstore.naver.com/withyoulove" class="HeaderNav-link new-window" target="_blank">스토어</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="RightAtc">
                <a href="#;" class="HeadReservationBtn">예약하기</a>
            </div>
        </div>
      </div>
    </header>
</template>
<script>
export default {
    data() {
        return {
            isBind:'',
        };
    },
    mounted() {
        this.$nextTick(this.HeaderEvent);
    },
    watch:{
        $route(){
            //주소 변경 감지후 메뉴 닫기 함수 실행
            const width = window.innerWidth
            if (width <= 1024)this.closeMoMenu()
            this.headRemoveClass()
        }
    },
    methods: {
        //메뉴열기/닫기
        HeaderMenu: function () {
            if (this.isBind === '') {
                this.isBind = 'is-View'
                document.getElementsByTagName('html')[0].classList.add('is-Hidden');
                document.getElementsByClassName('site-header')[0].classList.add('is-View');

            } else {
                this.isBind = ''
                document.getElementsByTagName('html')[0].classList.remove('is-Hidden');
                document.getElementsByClassName('site-header')[0].classList.remove('is-View');
            }
        },

        //주소 변경후 헤더 이벤트 클래스 삭제
        headRemoveClass: function () {
            document.getElementsByClassName('site-header')[0].classList.remove('nav-Up', 'nav-Down');
        },

        //메뉴 닫기
        closeMoMenu: function () {
            this.isBind = ''
            document.getElementsByTagName('html')[0].classList.remove('is-Hidden');
            document.getElementsByClassName('site-header')[0].classList.remove('is-View');
            const items = document.querySelectorAll('.HeaderNav-item');

            items.forEach(item => {
                item.classList.remove('is-Open')
            });
        },

        //모바일 뎁스 클릭
        MoNavClick: function(event){
            const _target = event.target.parentNode;
            const width = window.innerWidth
            if (width <= 1024){
                if(_target.classList.contains('is-Open')){
                    _target.classList.remove('is-Open')
                } else{
                    const items = document.querySelectorAll('.HeaderNav-item');

                    items.forEach(item => {
                        item.classList.remove('is-Open')
                    });
                    _target.classList.add('is-Open')
                }
            }
        },

        //스크롤 이벤트
        HeaderEvent: function () {
            let headerScroll;
            let lastHeadScroll = 0;

            function headerUpDown() {
                setInterval(function() {
                    if (headerScroll) {
                        headerScrolled();
                        headerScroll = false;
                    }
                }, 250);
            }

            function headerScrolled() {
                const target = document.getElementsByClassName('site-header')[0];
                const _winHeigth = window.innerHeight;
                const _docHeight = document.documentElement.offsetHeight;
                const _headerHeight = target.offsetHeight;
                const delta = 5;
                
                const scTop = document.scrollingElement.scrollTop;

                if(Math.abs(lastHeadScroll - scTop) <= delta){
                    return;
                }

                if (scTop > lastHeadScroll && scTop > _headerHeight){
                target.classList.add('nav-Up');
                target.classList.remove('nav-Down');
                } else {
                    if(scTop + _winHeigth < _docHeight) {
                        target.classList.add('nav-Down')
                        target.classList.remove('nav-Up');

                        if(scTop < delta) {
                            target.classList.remove('nav-Down')
                        }
                    }
                }
                
                lastHeadScroll = scTop;
            }

            //실행
            window.addEventListener('scroll',function() {
                headerScroll = true;
                headerUpDown();
            });
        }
    }
}
</script>
