<template>
  <top-banner :depthTitle="depthTitle" :imgUrl="imgUrl" :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></top-banner>
  <div class="ListSec">
    <div class="site-container">
        <h3 class="PageTitle">특별강의</h3>
        <div class="ListFilter">
            <list-search @searchData="getSearchData" id="search"></list-search>
        </div>
        <list-post :pagePost="pagePost" :postType="postType" :postDomain="postDomain" :TemplatePop="TemplatePop"></list-post>
        <div class="PostMore">
            <button type="button" class="PostMore-link" @click="getMore()" v-if="moreView">더보기</button>
        </div>
    </div>
  </div>
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import TopBanner from "../components/topbanner/topbanner.vue";
import ListSearch from "../components/filtersearch/filtersearch.vue";
import ListPost from "../components/post/post.vue";

export default {
  name:'SpecialLecture',
  setup() {
      const siteData = reactive({
      title: `특별강의 - WithYou 위드유치료교육연구소`,
      description: 'WithYou 위드유치료교육연구소 정보공유를 소개합니다.',
      canonical: 'https://withyoulove.co.kr/Information/SpecialLecture',
      })
      useHead({
          title: computed(() => siteData.title),
          meta: [
                  {
                      name: `description`,
                      content: computed(() => siteData.description),
                  },
          ],
          link: [
                {
                    rel: `canonical`,
                    content: computed(() => siteData.canonical),
                },
            ]
      })
  },
  components: {
    TopBanner,
    ListSearch,
    ListPost
  },
  data() {
        return {
           //상단 배너 컨텐츠
            depthTitle:'정보공유',
            imgUrl:'../../images/view/topbanner/topbanner-info.png',

            //빵가루
            firstTitle:"정보공유",
            lastTitle:'특별강의',
            breadCate:'Information',

            //리스트
            TemplatePop:false,
            postType:true,
            postDomain:'/Information/SpecialLecture/',
            pagePost:[],
            moreView:true,

            //리스트 세팅 정보
            apiUrl: 'https://withyoulove.co.kr:8000/api/blogList/',
            queryOptions: {
                per_page: 1,
                cat:6
            },

            //검색
            keyword:'',
        };
    },
    mounted() {
        if(this.$route.query.keyword){
            this.keyword = this.$route.query.keyword;
            this.queryOptions.per_page = 1;
        }

        //js/common.js 호출
        this.more('loadMore');
    },
    methods:{
        getMore() {
            this.more('loadMore');
        }
    }
}
</script>
