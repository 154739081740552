<template>
    <div class="PageError">
		<div class="site-container">
			<div class="PageError-ico"><img src="images/view/main/hero_star-min.png" alt=""></div>
			<h2 class="PageError-title">페이지를 찾을 수 없습니다.</h2>
			<p class="PageError-text">페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.</p>
			<div class="PageErrorBtn">
				<router-link class="PageErrorBtn-link" :to="{name: 'Home'}">홈으로 가기</router-link>
			</div>
		</div>
	</div>
</template>
<script>
export default {
    name:'',
    components: {},
    data() {
        return {
            sampleDate: ''
        };
    },
    mounted() {
        document.body.classList.add('page-notfound');
    },
    unmounted(){
        document.body.classList.remove('page-notfound');
    },
    methods: {}
}
</script>