import axios from 'axios';
axios.defaults.withCredentials = true

export default{
    methods:{
        //스크롤 애니메이션
        scrollAniView: function(trigger) {
            function scrollAni() {
                const items = document.querySelectorAll('.' + trigger);
                const _winHeigth = window.innerHeight;
                const _scroll = window.scrollY + _winHeigth;
                
                if (document.getElementsByClassName(trigger).length === 0) {
                    return;
                }
                
                items.forEach(item => {
                    const _offSetTop = item.offsetTop; 
                    if (_scroll > _offSetTop) {
                         item.classList.add('is-View');
                    }
                });
            }
            
            //실행
            window.addEventListener('scroll',function() {
                scrollAni();
            });
        },

        //검색 api
        getSearchData(keyword) {
            this.keyword = keyword;
            this.queryOptions.per_page = 1;
            this.$router.push({ 
                name: this.name, 
                query: { keyword: this.keyword } 
            });
        },

        //페이징 api
        getPaging(index){
            this.queryOptions.per_page = index;
            this.more('paging');
            this.$router.push({ 
                name: this.name, 
                query: { page: this.index, keyword: this.keyword } 
            });
        },

        //리스트 api
        more(state){
            axios.get(this.apiUrl,
                {
                    params: {
                        page: this.queryOptions.per_page,
                        keyword:this.keyword,
                        cat:this.queryOptions.cat
                    }
                }, 
            )
            .then(response => {
                let api = response.data.basic_info;
                let apiInfo = api;
                if(state == 'loadMore'){
                    apiInfo.forEach(response => {
                        this.pagePost.push(response)
                    });
                    this.queryOptions.per_page++;
                    if(response.data.paginations.next_page == 0){
                        this.moreView = false
                    }else{
                        this.moreView = true
                    }
                }
                if(state == 'paging'){
                    this.pagePost = response.data.basic_info;
                    this.totalPageCount = response.data.paginations.max_pages;
                    this.currentPage = response.data.paginations.current_page;
                }
            });
        },

        //메인 위드유소식 api
        mainNoticeMore(){
            axios.get(this.apiMainNotice,
            )
            .then(response => {
                let api = response.data.basic_info;
                let apiInfo = api;
                apiInfo.forEach(response => {
                    this.mainNotice.push(response)
                });
            });
        },

        //메인 특별강의 api
        mainSlideMore(){
            axios.get(this.apiSlideNotice,
            )
            .then(response => {
                let api = response.data.basic_info;
                let apiInfo = api;
                apiInfo.forEach(response => {
                    this.slides.push(response)
                });
            });
        },

        //MOU 슬라이드 api
        mouSlideMore(state){
            if(state == '1'){
                axios.get(this.apiSlideMou1,)
                .then(response => {
                    let api = response.data.basic_info.slides;
                    let apiInfo = api;
                    apiInfo.forEach(response => {
                        this.slides1.push(response)
                    });
                });
            }

            if(state == '2'){
                axios.get(this.apiSlideMou2,)
                .then(response => {
                    let api = response.data.basic_info.slides;
                    let apiInfo = api;
                    apiInfo.forEach(response => {
                        this.slides2.push(response)
                    });
                });
            }
            
            if(state == '3'){
                axios.get(this.apiSlideMou3,)
                .then(response => {
                    let api = response.data.basic_info.slides;
                    let apiInfo = api;
                    apiInfo.forEach(response => {
                        this.slides3.push(response)
                    });
                });
            }
        },
    }
}