<template>
    <top-banner :depthTitle="depthTitle" :imgUrl="imgUrl" :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></top-banner>
    <program-menu :isCurrent='isCurrent'></program-menu>
    <div class="ListSec">
      <div class="site-container">
          <h3 class="PageTitle">심리치료 및 상담</h3>
          <list-post :pagePost="pagePost" :postType="postType" :postDomain="postDomain" :TemplatePop="TemplatePop"></list-post>
          <list-paging :totalPageCount="totalPageCount" @firstUpdate="firstPage" @itemUpdate="itemPage" @prevUpdate="prevPage" @nextUpdate="nextPage" @lastUpdate="lastPage" :currentPage="currentPage"></list-paging>
      </div>
    </div>
  </template>
  <script>
  import {computed, reactive } from 'vue'
  import { useHead } from '@vueuse/head'
  import TopBanner from "../components/topbanner/topbanner.vue";
  import ListPost from "../components/programpost/programpost.vue";
  import ListPaging from "../components/paging/paging.vue";
  import ProgramMenu from "../components/programmenu/programmenu.vue";

  export default {
    name:'Psychotherapy',
    setup() {
        const siteData = reactive({
        title: `심리치료 및 상담 - WithYou 위드유치료교육연구소`,
        description: 'WithYou 위드유치료교육연구소 프로그램을 소개합니다.',
        canonical: 'https://withyoulove.co.kr/Program/Psychotherapy',
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                    {
                        name: `description`,
                        content: computed(() => siteData.description),
                    },
            ],
            link: [
                {
                    rel: `canonical`,
                    content: computed(() => siteData.canonical),
                },
            ]
        })
    },
    components: {
      TopBanner,
      ListPost,
      ListPaging,
      ProgramMenu,
    },
    data() {
          return {
             //상단 배너 컨텐츠
              depthTitle:'위드유 프로그램',
              imgUrl:'../../images/view/topbanner/topbanner-pro.png',
              firstTitle:"위드유 프로그램",
              lastTitle:'심리치료 및 상담',
              breadCate:'Program',

              //서브메뉴
              isCurrent:'2',
  
              //리스트
              TemplatePop:false,
              postType:false,
              postDomain:'/Program/Psychotherapy/',
              pagePost:[],
              moreView:true,
  
              //리스트 세팅 정보
              apiUrl: 'https://withyoulove.co.kr:8000/api/programList/',
              queryOptions: {
                  per_page: 1,
                  cat:2
              },

              //페이징
              totalPageCount:null,
              currentPage:1
          };
      },
      mounted() {
          if(this.$route.query.page){
              this.page = this.$route.query.page;
              this.queryOptions.per_page = this.page;
          }
          //js/common.js 호출
          this.more('paging');
      },
      methods:{
        itemPage:function(index){
            this.index = index;
            this.getPaging(index);
        },
        firstPage:function(index){
            this.index = index;
            this.index = 1
            this.getPaging(index);
        },
        prevPage:function(index){
            this.index = index;
            this.index--
            this.getPaging(index);
        },
        nextPage:function(index){
            this.index = index;
            this.index++
            this.getPaging(index);
        },
        lastPage:function(index){
            this.index = index;
            this.index = this.totalPageCount
            this.getPaging(index);
        },
      }
  }
  </script>
  