<template>
    <div class="ResearcherPopup">
        <div class="ResearcherPopupDim"></div>
        <div class="ResearcherPopupAct">
            <div class="ResearcherPopupActHeader">
                <button class="ResearcherPopupActHeader-close" @click="closePop" type="button"><span class="ab-text">닫기</span></button>
                <div class="ResearcherPopupActHeaderName">
                    <span class="ResearcherPopupActHeaderName-name">{{resPost.name}}</span>
                    <span class="ResearcherPopupActHeaderName-sub">{{resPost.position}}</span>
                </div>
            </div>
            <div class="ResearcherPopupActScorll">
                <!-- item -->
                <div v-if="education.length > 0" class="ResearcherPopupSec">
                    <div class="ResearcherPopupSecTitle">학력</div>
                    <div class="ResearcherPopupSecList">
                        <ul class="ResearcherPopupSecList-list">
                            <li class="ResearcherPopupSecList-item" :key="i" v-for="(post,i) in education">{{ post }}</li>
                        </ul>
                    </div>
                </div>
                <!-- .item -->
                <!-- item -->
                <div v-if="career.length > 0" class="ResearcherPopupSec">
                    <div class="ResearcherPopupSecTitle">상세 이력</div>
                    <div class="ResearcherPopupSecList">
                        <ul class="ResearcherPopupSecList-list">
                            <li class="ResearcherPopupSecList-item" :key="i" v-for="(post,i) in career">{{ post }}</li>
                        </ul>
                    </div>
                </div>
                <!-- .item -->
                <!-- item -->
                <div v-if="book.length > 0" class="ResearcherPopupSec">
                    <div class="ResearcherPopupSecTitle">저서</div>
                    <div class="ResearcherPopupSecList">
                        <ul class="ResearcherPopupSecList-list">
                            <li class="ResearcherPopupSecList-item" :key="i" v-for="(post,i) in book">{{ post }}</li>
                        </ul>
                    </div>
                </div>
                <!-- .item -->
                <!-- item -->
                <div v-if="book.length > 0" class="ResearcherPopupSec">
                    <div class="ResearcherPopupSecTitle">논문</div>
                    <div class="ResearcherPopupSecList">
                        <ul class="ResearcherPopupSecList-list">
                            <li class="ResearcherPopupSecList-item" :key="i" v-for="(post,i) in thesis">{{ post }}</li>
                        </ul>
                    </div>
                </div>
                <!-- .item -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
    name:'',
    components: {
        
    },
    props:['resPost','contid','career','education','book', 'thesis'],
    methods: {
        closePop: function () {
            document.getElementsByTagName('html')[0].classList.remove('is-Hidden');
            document.getElementsByClassName('ResearcherPopup')[0].classList.remove('is-View');
        },
    }
}
</script>