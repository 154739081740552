<template>
  <div class="PgStaTest">  
    <div class="pure-container">
      <!-- PgStaTestTitle -->
      <div class="PgStaTestTitle">
        <h2 class="PgStaTestTitle-tit">STA 기질 검사</h2>
        <p class="PgStaTestTitle-sub">STA-Q는 ‘진정한 나’를 탐색하기 위한 검사입니다.</p>
      </div>
      <!-- .PgStaTestTitle -->

      <!-- PgStaTestCont -->
      <div class="PgStaTestCont">
        <div class="PgStaTestCont-img"><img src="/images/view/statest/img-starCharacter.png" /></div>
        <div class="AboutTestInfo">
          <p class="AboutTestInfo-txt">STA기질검사는 많은 임상을 통해 최은정 대표가 고유한 기질을 발견하기 위해 개발한 기질검사입니다.<br /><span class="AboutTestInfo-textsub"><strong>S</strong>elf-Discovery <strong>T</strong>emperament <strong>A</strong>ssessment</span></p>
          <div class="AboutTestInfoBox">
            <p class="AboutTestInfoBox-txt">기질은 타고난 경향성입니다.</p>
            <p class="AboutTestInfoBox-txt">기질은 16가지 유형이 있습니다.</p>
            <p class="AboutTestInfoBox-txt">기질은 고유한 나의 빛깔입니다.</p>
            <p class="AboutTestInfoBox-txt">기질은 좋고 나쁜 기질이 없습니다.</p>
          </div>
          <p class="AboutTestInfo-txt">이제부터 나의 기질 유형을 찾아볼까요?</p>
        </div>
      </div>
      <!-- .PgStaTestCont -->
      <!-- btn -->
      <div class="PgStaTestContBtn">
        <router-link :to="{name: 'UnderTest'}" class="PgStaTestContBtn-link">검사 시작</router-link>
      </div>
      <!-- .btn -->
    </div>
  </div>
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

export default {
  name:'TestIntro',
  setup() {
      const siteData = reactive({
      title: `STA 기질 검사 - WithYou 위드유치료교육연구소`,
      description: 'STA-Q는 ‘진정한 나’를 탐색하기 위한 검사입니다.',
      canonical: 'https://withyoulove.co.kr/testintro',
      })
      useHead({
          title: computed(() => siteData.title),
          meta: [
                  {
                      name: `description`,
                      content: computed(() => siteData.description),
                  },
          ],
          link: [
                {
                    rel: `canonical`,
                    content: computed(() => siteData.canonical),
                },
            ]
      })
  },
  mounted() {
    document.body.classList.add('no-floting');
  },
  unmounted() {
    document.body.classList.remove('no-floting');
  },
}
</script>
