import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mixins from './mixins'
import VueRecaptcha from "vue3-recaptcha-v2";
import BaseButton from "./components/button/button";
import BaseHeadline from "./components/typography/headline";
import { createHead } from '@vueuse/head'
import componentJs from './js/common'
import './assets/theme/scss/common.scss'
const head = createHead()
const app = createApp(App)
app.use(router)
app.use(head)
app.mixin(mixins)
app.mixin(componentJs)
app.use(VueRecaptcha, {
    siteKey: "6LcFnhsmAAAAAKXjcRFDfYeNJViio5G_XSdqOioB",
    alterDomain: false, // default: false
})
app.component('BaseButton', BaseButton)//전역생성
app.component('BaseHeadline', BaseHeadline)//전역생성
app.mount('#app')
