<template>
    <div class="ResearcherPost">
        <ul class="ResearcherPost-list">
            <template :key="i" v-for="(post,i) in pagePost">
                <li class="ResearcherPost-item" >
                    <div class="ResearcherPostItem">
                        <a href="javascript:void(0)" @click="OpenPop(i)" class="ResearcherPostItem-link">
                            <div class="ResearcherPostItemThumb">
                                <img class="ResearcherPostItemThumb-img" v-if="post.thumbnail" :src="post.thumbnail" alt="" />
                                <img class="ResearcherPostItemThumb-img" v-else :src="'/images/view/researcher/research-thumb.png'" alt="" />
                                <span class="ResearcherPostItemThumbName">
                                    <span class="ResearcherPostItemThumbName-title">{{post.name}}</span>
                                    <span class="ResearcherPostItemThumbName-sub">{{post.position}}</span>
                                </span>
                            </div>
                            <div class="ResearcherPostItemCnt">
                                <ul class="ResearcherPostItemCnt-list">
                                    <li class="ResearcherPostItemCnt-item" :key="i" v-for="(info,i) in post.info">{{info}}</li>
                                </ul>
                            </div>
                            <div class="ResearcherPostItemLink">상세 약력 보기</div>
                        </a>
                    </div>
                </li>
            </template>
            <li v-if="pagePost.length === 0" class="ResearcherPost-item no-Data">데이터가 존재하지 않습니다.</li>
        </ul>
    </div>
    <researcher-pop :contid="contid" :resPost="resPost" :career="career" :education="education" :book="book" :thesis="thesis"></researcher-pop>
</template>
<script>
import ResearcherPop from "../researcherpop/researcherpop.vue";

import axios from 'axios';
export default {
    components:{
        ResearcherPop
    },
    props:['pagePost'],
    data(){
        return{
            Thumb:'../images/view/researcher/research-thumb.png',

            //데이터
            contid:'',
            resPost: [],
            career: [],
            education:[],
            book:[],
            thesis:[],
            popUrl:'https://withyoulove.co.kr:8000/api/teacherView/',
        }
    },
    methods:{
        OpenPop: function (index) {
            this.contid = this.pagePost[index].id;
            axios.get(this.popUrl + parseInt(this.contid),
                {
                    params: {
                        page:this.page,
                        keyword:this.keyword,
                    }
                }, 
            )
            .then((response) => {
                this.resPost = response.data.basic_info;
                this.career = response.data.basic_info.career;
                this.education = response.data.basic_info.education;
                this.book = response.data.basic_info.book;
                this.thesis = response.data.basic_info.thesis;
            })
            document.getElementsByTagName('html')[0].classList.add('is-Hidden');
            document.getElementsByClassName('ResearcherPopup')[0].classList.add('is-View');
        },
    }
}
</script>