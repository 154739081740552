<template>
  <top-banner :depthTitle="depthTitle" :imgUrl="imgUrl" :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></top-banner>
    <div class="pageLoction">
        <div class="site-container">  
            <h3 class="PageTitle">오시는길</h3>
            <!-- MapBox -->
            <div class="MapBox">
                <div class="MapBox-inner">
                   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.490121442289!2d126.91678187656882!3d37.40188873355102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b60d0605e6efb%3A0xb842e46701227c8e!2z6rK96riw64-EIOyViOyWkeyLnCDrp4zslYjqtawg7JWI7JaR66GcIDMzNg!5e0!3m2!1sko!2skr!4v1691629036046!5m2!1sko!2skr" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <!-- .MapBox -->
            <div class="LocationInfo">
                <ul class="LocationInfo-list">
                    <!-- item -->
                    <li class="LocationInfo-item">
                        <dl class="LocationItem">
                            <dt class="LocationItemTitle">
                                <span class="LocationItemTitle-txt">주소</span>
                            </dt>
                            <dd class="LocationItemCont">
                                <p class="LocationText">경기도 안양시 만안구 안양로 336 금강빌딩 6층 위드유치료교육연구소</p>
                                <p class="LocationText">대표번호<a href="tel:0314691028">031-469-1028</a></p>
                            </dd>
                        </dl>
                    </li>
                    <!-- .item -->
                    <!-- item -->
                    <li class="LocationInfo-item">
                        <dl class="LocationItem">
                            <dt class="LocationItemTitle">
                                <span class="LocationItemTitle-txt">오시는길</span>
                            </dt>
                            <dd class="LocationItemCont">
                                <p class="LocationText">지하철 안양역 1번 출구</p>
                                <p class="LocationText">2,9,11,12,81 번 마을버스 승차</p>
                                <p class="LocationText">교보생명  정류장에서 하차</p>
                                <p class="LocationText">교보생명 앞 횡단보보 건넌 후 왼쪽 파리바게트 방향으로 도보 1분</p>
                                <p class="LocationText">1층에 CU가 있는 건물 6층으로 와주시면 됩니다.</p>
                            </dd>
                        </dl>
                    </li>
                    <!-- .item -->
                    <!-- item -->
                    <li class="LocationInfo-item">
                        <dl class="LocationItem">
                            <dt class="LocationItemTitle">
                                <span class="LocationItemTitle-txt">이용시간</span>
                            </dt>
                            <dd class="LocationItemCont">
                                <div class="LocaBullet">
                                    <dl class="LocaBullet-item">
                                        <dt class="LocaBullet-tit"><p class="LocationText">평일</p></dt>
                                        <dd class="LocaBullet-txt"><p class="LocationText">10:00 ~ 18:00</p></dd>
                                    </dl>
                                    <dl class="LocaBullet-item">
                                        <dt class="LocaBullet-tit"><p class="LocationText">주말</p></dt>
                                        <dd class="LocaBullet-txt"><p class="LocationText">휴무</p></dd>
                                    </dl>
                                </div>
                                <div class="LocaNoti">
                                    <p class="LocaNoti-txt">
                                        치료/교육/상담 중 전화 연결이 원활하지 않을 수 있습니다. <br />
                                        게시된 이용 시간은 평균적인 시간이며 실 이용시간은 프로그램과 담당 선생님 별로 상이합니다. <br />
                                        궁금하신 점은 문의하기 혹은 연구소 번호로 메세지 남겨주시면 순차대로 답변드릴 수 있도록 하겠습니다.
                                    </p>
                                </div>
                            </dd>
                        </dl>
                    </li>
                    <!-- .item -->
                    <!-- item -->
                    <li class="LocationInfo-item">
                        <dl class="LocationItem">
                            <dt class="LocationItemTitle">
                                <span class="LocationItemTitle-txt">편의 서비스</span>
                            </dt>
                            <dd class="LocationItemCont">
                                <div class="ServiceBox">
                                    <ul class="ServiceBox-list">
                                        <li class="ServiceBox-item">
                                            <div class="ServiceItem">
                                                <span class="ServiceItem-icon"><img src="/images/view/contact/ico-parking.png" /></span>
                                                <span class="ServiceItem-txt">주차</span>
                                            </div>
                                        </li>
                                        <li class="ServiceBox-item">
                                            <div class="ServiceItem">
                                                <span class="ServiceItem-icon"><img src="/images/view/contact/ico-wifi.png" /></span>
                                                <span class="ServiceItem-txt">무선 인터넷</span>
                                            </div>
                                        </li>
                                        <li class="ServiceBox-item">
                                            <div class="ServiceItem">
                                                <span class="ServiceItem-icon"><img src="/images/view/contact/ico-restroom.png" /></span>
                                                <span class="ServiceItem-txt">남/녀 화장실 구분</span>
                                            </div>
                                        </li>
                                        <li class="ServiceBox-item">
                                            <div class="ServiceItem">
                                                <span class="ServiceItem-icon"><img src="/images/view/contact/ico-disabled.png" /></span>
                                                <span class="ServiceItem-txt">장애인 편의시설</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="LocaNoti">
                                    <p class="LocaNoti-txt">※ 만차 시 공영주차장 주차<br class="br-mo" />(<a href="https://map.naver.com/p/entry/place/1157094410" target="_blank">임시공영주차장</a>, <a href="https://map.naver.com/p/entry/place/37147126?c=15.00,0,0,0,dh" target="_blank">일번가공영주차장입구</a>, <a href="https://map.naver.com/p/entry/place/1475904248?c=15.00,0,0,0,dh" target="_blank">삼덕공원공영주차장</a>)</p>
                                </div>
                            </dd>
                        </dl>
                    </li>
                    <!-- .item -->
                </ul>

            </div>
        </div>
    </div>
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import TopBanner from "../components/topbanner/topbanner.vue";
export default {
    name:'Location',
    setup() {
        const siteData = reactive({
        title: `오시는길 - WithYou 위드유치료교육연구소`,
        description: 'WithYou 위드유치료교육연구소 오시는길',
        canonical: 'https://withyoulove.co.kr/Support/location',
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                    {
                        name: `description`,
                        content: computed(() => siteData.description),
                    },
            ],
            link: [
                {
                    rel: `canonical`,
                    content: computed(() => siteData.canonical),
                },
            ]
        })
    },
    components: {
        TopBanner
    },
    data() {
        return {
        //상단 배너 컨텐츠
            depthTitle:'고객지원',
            imgUrl:'../images/view/topbanner/topbanner-location.png',

            //빵가루
            breadCrumb:[
                {"item_title":"고객지원"},
                {"item_title":"오시는길"},
            ],
            firstTitle:"고객지원",
            lastTitle:'오시는길',
            breadCate:'Support',
        };
    },
}
</script>
