<template>
    <div class="CardPost">
        <div class="CardPostBg">
            <img class="CardPostBg-img" :src="postImg" alt="" />
        </div>
        <h4 class="CardPostTitle">
            <span class="CardPostTitle-tit">{{ postTitle }}</span>
            <router-link :to="postUrl" class="CardPostTitle-link">자세히 보기</router-link>
        </h4>
    </div>
</template>
<script>
export default {
    props:['postUrl','postTitle','postImg'],
}
</script>