<template>
    <form class="PgStaForm-form" @submit.prevent="formResult">
        <!-- Question 1 -->
        <template v-if="question === 1">
            <div class="PgStaTest">  
                <div class="pure-container">
                    <div class="PgStaTestTitle">
                        <div class="PgStaTestTitle-logo"><img src="images/view/statest/logo-sta03.svg" /></div>
                    </div>
                    <div class="StaProgress">
                        <p class="StaProgressText"><span class="StaProgressText-txt">Question 1 of 8</span></p>
                        <div class="StaProgressBar">
                            <span class="StaProgressBar-line" style="width:12.5%"></span>
                        </div>
                    </div>
                    <div class="PgStaTestCont">
                        <div class="QuestionBox">
                            <div class="QuestionBox-tit">연령대를 선택해주세요</div>
                            <div class="QuestionBox-cont">
                                <div class="HalfBox">
                                    <ul class="HalfBox-list">
                                        <!-- 
                                        <li class="HalfBox-item">
                                            <div class="HalfBoxItem">
                                                <input type="radio" id="age01" name="age" class="HalfBoxItem-input" @change="checkChange($event)" value="1" />
                                                <label class="HalfBoxItem-label" for="age01"><span class="HalfBoxItem-txt">영유아</span></label>
                                            </div>
                                        </li>
                                        <li class="HalfBox-item">
                                            <div class="HalfBoxItem">
                                                <input type="radio" id="age02" name="age" class="HalfBoxItem-input" @change="checkChange($event)" value="2" />
                                                <label class="HalfBoxItem-label" for="age02"><span class="HalfBoxItem-txt">아동</span></label>
                                            </div>
                                        </li> -->
                                        <li class="HalfBox-item">
                                            <div class="HalfBoxItem">
                                                <input type="radio" id="age03" name="age" class="HalfBoxItem-input" @change="checkChange($event)" value="3" />
                                                <label class="HalfBoxItem-label" for="age03"><span class="HalfBoxItem-txt">청소년</span></label>
                                            </div>
                                        </li>
                                        <li class="HalfBox-item">
                                            <div class="HalfBoxItem">
                                                <input type="radio" id="age04" name="age" class="HalfBoxItem-input" @change="checkChange($event)" value="4" />
                                                <label class="HalfBoxItem-label" for="age04"><span class="HalfBoxItem-txt">성인</span></label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="PgStaTestContBtn">
                        <button type="button" class="PgStaTestContBtn-link" @click="questionNext" :disabled="disabled">다음</button>
                    </div>
                </div>
            </div>
        </template>
        <!-- .Question 1 -->
        <!-- Question 2 -->
        <template v-else>
            <div class="PgStaTest">
                <div class="pure-container">
                    <div class="PgStaTestTitle">
                        <div class="PgStaTestTitle-logo"><img src="images/view/statest/logo-sta03.svg" /></div>
                    </div>
                    <div class="StaProgress">
                        <p class="StaProgressText"><span class="StaProgressText-txt">Question {{ barCount }} of 8</span></p>
                        <div class="StaProgressBar">
                            <span class="StaProgressBar-line" :style="'width:' + barLine + '%'"></span>
                        </div>
                    </div>
                    <div class="PgStaTestCont">
                        <div class="QuestionBox">
                            <div v-if="this.barCount == 2" class="QuestionBox-tit">나에게 제일 중요한 욕구를 선택해 주세요.</div>
                            <div v-if="this.barCount == 3" class="QuestionBox-tit">나의 성격이 아닌, 나의 타고난 기질에 가장 가까운 특징을 선택해주세요.</div>
                            <div v-if="this.barCount > 3" class="QuestionBox-tit">나의 정서행동 특징에 가장 가까운 기질 특징을 선택해 주세요.</div>
                            <div v-if="this.barCount == 4 || this.barCount == 5 || this.barCount == 6 || this.barCount == 7 || this.barCount == 8" class="QuestionBox-titsub">위에 나의 특징이 없다면, 2,3 페이지에서 자신의 욕구와 특징을 다시 선택해주세요.</div>
                        </div>
                        <div class="FullBox">
                            <ul class="FullBox-list">
                                <template :key="i" v-for="(item,i) in questPost">
                                    <li v-if="this.barCount == 2" class="FullBox-item">
                                        <div class="FullBoxItem">
                                            <input type="radio" :id="'opt'+ i" name="Temp1" class="FullBoxItem-input" @change="questChange1(i)" />
                                            <label class="FullBoxItem-label" :for="'opt'+ i"><span class="FullBoxItem-txt">{{ item.title }}</span></label>
                                        </div>
                                    </li>
                                    <template v-else />
                                    <template :key="i" v-for="(question2,i) in item.question2">
                                        <li v-if="this.barCount == 3 && item.id == checkIndex1" class="FullBox-item">
                                            <div class="FullBoxItem">
                                                <input type="radio" :id="'opt'+ i" name="Temp2" class="FullBoxItem-input" @change="questChange2(i)" />
                                                <label class="FullBoxItem-label" :for="'opt'+ i"><span class="FullBoxItem-txt">{{ question2.title }}</span></label>
                                            </div>
                                        </li>
                                        <template v-else />
                                        <template :key="i" v-for="(question3,i) in question2.question3">
                                            <li v-if="this.barCount == 4 && item.id == checkIndex1 && question2.id == checkIndex2" class="FullBox-item">
                                                <div class="FullBoxItem">
                                                    <input type="radio" :id="'opt'+ i" name="Temp3" class="FullBoxItem-input" @change="questChange3(i)" />
                                                    <label class="FullBoxItem-label" :for="'opt'+ i"><span class="FullBoxItem-txt">{{ question3.title }}</span></label>
                                                </div>
                                            </li>
                                            <template v-else />
                                        </template>
                                        <template :key="i" v-for="(question4,i) in question2.question4">
                                            <li v-if="this.barCount == 5 && item.id == checkIndex1 && question2.id == checkIndex2" class="FullBox-item">
                                                <div class="FullBoxItem">
                                                    <input type="radio" :id="'opt'+ i" name="Temp4" class="FullBoxItem-input" @change="questChange4(i)" />
                                                    <label class="FullBoxItem-label" :for="'opt'+ i"><span class="FullBoxItem-txt">{{ question4.title }}</span></label>
                                                </div>
                                            </li>
                                            <template v-else />
                                        </template>
                                        <template :key="i" v-for="(question5,i) in question2.question5">
                                            <li v-if="this.barCount == 6 && item.id == checkIndex1 && question2.id == checkIndex2" class="FullBox-item">
                                                <div class="FullBoxItem">
                                                    <input type="radio" :id="'opt'+ i" name="Temp5" class="FullBoxItem-input" @change="questChange5(i)" />
                                                    <label class="FullBoxItem-label" :for="'opt'+ i"><span class="FullBoxItem-txt">{{ question5.title }}</span></label>
                                                </div>
                                            </li>
                                            <template v-else />
                                        </template>
                                        <template :key="i" v-for="(question6,i) in question2.question6">
                                            <li v-if="this.barCount == 7 && item.id == checkIndex1 && question2.id == checkIndex2" class="FullBox-item">
                                                <div class="FullBoxItem">
                                                    <input type="radio" :id="'opt'+ i" name="Temp6" class="FullBoxItem-input" @change="questChange6(i)" />
                                                    <label class="FullBoxItem-label" :for="'opt'+ i"><span class="FullBoxItem-txt">{{ question6.title }}</span></label>
                                                </div>
                                            </li>
                                            <template v-else />
                                        </template>
                                        <template :key="i" v-for="(question7,i) in question2.question7">
                                            <li v-if="this.barCount == 8 && item.id == checkIndex1 && question2.id == checkIndex2" class="FullBox-item">
                                                <div class="FullBoxItem">
                                                    <input type="radio" :id="'opt'+ i" name="Temp7" class="FullBoxItem-input" @change="questChange7(i)" />
                                                    <label class="FullBoxItem-label" :for="'opt'+ i"><span class="FullBoxItem-txt">{{ question7.title }}</span></label>
                                                </div>
                                            </li>
                                            <template v-else />
                                        </template>
                                    </template>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <div class="PgStaTestContBtn">
                        <ul class="PgStaTestContBtn-list">
                            <li class="PgStaTestContBtn-item">
                                <button type="button" class="PgStaTestContBtn-link" @click="questionPrev">이전</button>
                            </li>
                            <li v-if="this.barCount < 8" class="PgStaTestContBtn-item">
                                <button type="button" class="PgStaTestContBtn-link" @click="questionNext" :disabled="disabled">다음</button>
                            </li>
                            <li v-else class="PgStaTestContBtn-item">
                                <button tyle="submit" class="PgStaTestContBtn-link" :disabled="disabled">결과보기</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
</form>
    <!-- .Question 2 -->
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import axios from 'axios'
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
export default {
  name:'UnderTest',
  setup() {
      const siteData = reactive({
      title: `STA 기질 검사 - WithYou 위드유치료교육연구소`,
      description: 'WithYou 위드유치료교육연구소 고객지원',
      })
      useHead({
          title: computed(() => siteData.title),
          meta: [
                  {
                      name: `description`,
                      content: computed(() => siteData.description),
                  },
          ],
      })
  },
  data(){
    return{
        question:1,
        barLine:12.5,
        barCount:1,
        disabled:true,
        checkValue:null,
        checkIndex1:null,
        checkIndex2:null,
        checkIndex3:null,
        checkIndex4:null,
        checkIndex5:null,
        checkIndex6:null,
        checkIndex7:null,
        questItem1:null,
        questItem2:null,
        checkIndex3Key:[],
        checkIndex4Key:[],
        checkIndex5Key:[],
        checkIndex6Key:[],
        checkIndex7Key:[],
        resultNum:null,
        formTitle:null,
        formCard:[],
        questPost:[
            {id:1, title:'완벽과 인정', 
                question2:[
                    {id:1, title:'모든 완벽하게 다 잘하고 싶다. 그래서 힘들어도 참는다.',
                        question3:[
                            {title:'움직임과 활동량이 많다.', key:'1'},
                            {title:'움직이는 활동을 좋아한다.', key:'2'},
                            {title:'무엇이든 해보고 싶은 동기가 높다.', key:'3'},
                            {title:'스스로하려는 적극성이 높다.', key:'4'},
                            {title:'주도하고 싶은 마음이 많다.', key:'5'},
                            {title:'다른 사람이 잘하는 것을 부러워한다.', key:'6'},
                        ],
                        question4:[
                            {title:'오감이 예민하다.', key:'13'},
                            {title:'자다가 잘 깨고 깊은 수면이 어렵다.', key:'14'},
                            {title:'환경적 변화에 민감하다.', key:'15'},
                            {title:'다른 사람의 불편한 행동에 예민하다.', key:'16'},
                            {title:'다른 사람의 표정과 말투변화에 민감하다.', key:'17'},
                            {title:'섬세한 감정변화를 잘 알아차린다.', key:'18'},
                        ],
                        question5:[
                            {title:'자고 일어나는 시간이 규칙적이다.', key:'25'},
                            {title:'하루 일과가 일관적이다.', key:'27'},
                            {title:'정리정돈을 잘한다.', key:'28'},
                            {title:'순응적이다.', key:'29'},
                            {title:'행동과 요구가 예측 가능하다.', key:'30'},
                            {title:'질서와 순서를 확실하게 지킨다.', key:'31'},
                        ],
                        question6:[
                            {title:'밖에 나가는 것을 좋아하지 않는다.', key:'79'},
                            {title:'낯선 장소에 적응하기 어렵다.', key:'80'},
                            {title:'혼자 있기를 좋아한다.', key:'81'},
                            {title:'사람들과 만나면 에너지가 소진된다.', key:'82'},
                            {title:'집단에 적응하는 것이 어렵다.',key:'83'},
                            {title:'사람들과의 관계에서 경계심이 있다.', key:'84'},
                        ],
                        question7:[
                            {title:'기분이 나쁠 때 흥분하지않는다.', key:'43'},
                            {title:'내 뜻대로 되지 않을 때 참거나 넘긴다.', key:'44'},
                            {title:'작은 불편감들은 표현하지 않는다.', key:'45'},
                            {title:'섭섭해도 잘 표현하지 않는다.', key:'46'},
                            {title:'짜증감이 쌓이다가 힘들면 분출된다.', key:'47'},
                            {title:'화가 나도 불편한 마음을 참는다.', key:'68'},
                        ]
                    },
                    {id:2, title:'생각이 많고 신중한 편이다. 빨리 생각하는 건 어렵다.',
                        question3:[
                            {title:'오감이 예민하다.', key:'13'},
                            {title:'자다가 잘 깨고 깊은 수면이 어렵다.', key:'14'},
                            {title:'환경적 변화에 민감하다.', key:'15'},
                            {title:'다른 사람의 불편한 행동에 예민하다.', key:'16'},
                            {title:'다른 사람의 표정과 말투변화에 민감하다.', key:'17'},
                            {title:'섬세한 감정변화를 잘 알아차린다.', key:'18'},
                        ],
                        question4:[
                            {title:'호불호가 분명하다.', key:'49'},
                            {title:'지적 욕구가 높다.', key:'50'},
                            {title:'원하는 것을 얻을 때까지 요구한다.', key:'51'},
                            {title:'몰입과 의지력이 강하다.', key:'52'},
                            {title:'감정을 진정하는 시간이 오래 걸린다.', key:'53'},
                            {title:'기억이나 감정을 오래 품고 있다.', key:'54'},
                        ],
                        question5:[
                            {title:'움직임과 활동량이 적다.', key:'7'},
                            {title:'차분한 활동을 좋아한다.', key:'8'},
                            {title:'무엇을 해보고 싶은 동기가 낮다.', key:'9'},
                            {title:'자발적이지 않고 소극적이다.', key:'10'},
                            {title:'주도하고 싶은 마음이 적다.', key:'11'},
                            {title:'다른 사람이 잘하는 것에 관심이 없다.', key:'12'},
                        ],
                        question6:[
                            {title:'기분이 나쁠 때 흥분하지않는다.', key:'43'},
                            {title:'내 뜻대로 되지 않을 때 참거나 넘긴다.', key:'44'},
                            {title:'작은 불편감들은 표현하지 않는다.', key:'45'},
                            {title:'섭섭해도 잘 표현하지 않는다.', key:'46'},
                            {title:'짜증감이 쌓이다가 힘들면 분출된다.', key:'47'},
                            {title:'화가 나도 불편한 마음을 참는다.', key:'68'},
                        ],
                        question7:[
                            {title:'관심이 없으면 집중하지 않는다.', key:'67'},
                            {title:'집중 시간이 짧다.', key:'68'},
                            {title:'지시를 들어도 잘 잊어버린다.', key:'69'},
                            {title:'주변 자극에 주의가 쉽게 흐트러진다.', key:'70'},
                            {title:'타인의 말과 행동에 주의를 기울이지 않는다.', key:'71'},
                            {title:'불러도 잘 반응하지 않는다.', key:'72'},
                        ]
                    },
                    {id:3, title:'처음에는 수줍지만 막상 사람들을 만나면 주도적으로 잘 하고 싶다.',
                        question3:[
                            {title:'움직임과 활동량이 많다.', key:'1'},
                            {title:'움직이는 활동을 좋아한다.', key:'2'},
                            {title:'무엇이든 해보고 싶은 동기가 높다.', key:'3'},
                            {title:'스스로하려는 적극성이 높다.', key:'4'},
                            {title:'주도하고 싶은 마음이 많다.', key:'5'},
                            {title:'다른 사람이 잘하는 것을 부러워한다.', key:'6'},
                        ],
                        question4:[
                            {title:'오감이 예민하다.', key:'13'},
                            {title:'자다가 잘 깨고 깊은 수면이 어렵다.', key:'14'},
                            {title:'환경적 변화에 민감하다.', key:'15'},
                            {title:'다른 사람의 불편한 행동에 예민하다.', key:'16'},
                            {title:'다른 사람의 표정과 말투변화에 민감하다.', key:'17'},
                            {title:'섬세한 감정변화를 잘 알아차린다.', key:'18'},
                        ],
                        question5:[
                            {title:'바깥 활동을 좋아한다.', key:'73'},
                            {title:'낯선 장소에 유연하게 적응한다.', key:'74'},
                            {title:'집단에 적응을 잘한다.', key:'75'},
                            {title:'많은 사람들과 어울리기를 좋아한다.', key:'76'},
                            {title:'사람들을 만나면 에너지가 생긴다.', key:'77'},
                            {title:'사람들과 친밀한 관계를 형성한다.', key:'78'},
                        ],
                        question6:[
                            {title:'익숙한 것보다 새로운 것을 좋아한다.', key:'85'},
                            {title:'새로운 경험에 경계심 없이 접근한다.', key:'86'},
                            {title:'새로운 상황과 환경을 즐긴다.', key:'87'},
                            {title:'처음 만난 사람도 쉽게 친해진다.', key:'88'},
                            {title:'주변에 관심이 많다.', key:'89'},
                            {title:'새로운 사람을 사귀고 싶어한다.', key:'90'},
                        ],
                        question7:[
                            {title:'기분이 나쁠 때 흥분하지 않는다.', key:'43'},
                            {title:'내 뜻대로 되지 않을 때 참거나 넘긴다.', key:'44'},
                            {title:'작은 불편감들은 표현하지 않는다.', key:'45'},
                            {title:'섭섭해도 잘 표현하지 않는다.', key:'46'},
                            {title:'짜증감이 쌓이다가 힘들면 분출된다.', key:'47'},
                            {title:'화가 나도 불편한 마음을 참는다.', key:'68'},
                        ]
                    },
                    {id:4, title:'내가 잘하는 것을 더 잘하고 싶다. 여러 사람들과 어울리는 것은 지친다.',
                        question3:[
                            {title:'오감이 예민하다.', key:'13'},
                            {title:'자다가 잘 깨고 깊은 수면이 어렵다.', key:'14'},
                            {title:'환경적 변화에 민감하다.', key:'15'},
                            {title:'다른 사람의 불편한 행동에 예민하다.', key:'16'},
                            {title:'다른 사람의 표정과 말투변화에 민감하다.', key:'17'},
                            {title:'섬세한 감정변화를 잘 알아차린다.', key:'18'},
                        ],
                        question4:[
                            {title:'주의 깊게 잘 듣고 반응한다.', key:'61'},
                            {title:'지시를 명확하게 듣고 반응한다.', key:'62'},
                            {title:'관심이 없어도 집중한다.', key:'63'},
                            {title:'주변 자극에도 주의를 유지한다.', key:'64'},
                            {title:'집중 시간이 길다.', key:'65'},
                            {title:'타인의 말과 행동에 주의를 기울인다.', key:'66'},
                        ],
                        question5:[
                            {title:'기분이 나쁠 때 흥분하지 않는다.', key:'43'},
                            {title:'내 뜻대로 되지 않을 때 참거나 넘긴다.', key:'44'},
                            {title:'작은 불편감들은 표현하지 않는다.', key:'45'},
                            {title:'섭섭해도 잘 표현하지 않는다.', key:'46'},
                            {title:'짜증감이 쌓이다가 힘들면 분출된다.', key:'47'},
                            {title:'화가 나도 불편한 마음을 참는다.', key:'68'},
                        ],
                        question6:[
                            {title:'굳이 새로운 것을 시도해보지 않는다.', key:'91'},
                            {title:'새로운 경험 하기 전 탐색시간이 길다.', key:'92'},
                            {title:'새로운 환경을 싫어한다.', key:'93'},
                            {title:'주변에 관심이 별로 없다.', key:'94'},
                            {title:'낯선 사람과 익숙해지는데 시간이 오래 걸린다.', key:'95'},
                            {title:'거의 익숙한 사람들과 지낸다.', key:'96'},
                        ],
                        question7:[
                            {title:'밖에 나가는 것을 좋아하지 않는다.', key:'79'},
                            {title:'낯선 장소에 적응하기 어렵다.', key:'80'},
                            {title:'혼자 있기를 좋아한다.', key:'81'},
                            {title:'사람들과 만나면 에너지가 소진된다.', key:'82'},
                            {title:'집단에 적응하는 것이 어렵다.',key:'83'},
                            {title:'사람들과의 관계에서 경계심이 있다.', key:'84'},
                        ]
                    }
                ]
            },
            {id:2, title:'주체성과 성취', 
                question2:[
                    {id:1, title:'하고 싶은건 끝까지 요구하고 실행해야 직성이 풀린다.',
                        question3:[
                            {title:'움직임과 활동량이 많다.', key:'1'},
                            {title:'움직이는 활동을 좋아한다.', key:'2'},
                            {title:'무엇이든 해보고 싶은 동기가 높다.', key:'3'},
                            {title:'스스로하려는 적극성이 높다.', key:'4'},
                            {title:'주도하고 싶은 마음이 많다.', key:'5'},
                            {title:'다른 사람이 잘하는 것을 부러워한다.', key:'6'},
                        ],
                        question4:[
                            {title:'호불호가 분명하다.', key:'49'},
                            {title:'지적 욕구가 높다.', key:'50'},
                            {title:'원하는 것을 얻을 때까지 요구한다.', key:'51'},
                            {title:'몰입과 의지력이 강하다.', key:'52'},
                            {title:'감정을 진정하는 시간이 오래 걸린다.', key:'53'},
                            {title:'기억이나 감정을 오래 품고 있다.', key:'54'},
                        ],
                        question5:[
                            {title:'기분이 나쁠 때 쉽게 흥분한다.', key:'37'},
                            {title:'내 뜻대로 되지 않을 때, 금방 화를 낸다.', key:'38'},
                            {title:'작은 불편감에도 신경질을 낸다.', key:'39'},
                            {title:'섭섭하면 화가 난다.', key:'40'},
                            {title:'무엇을 하다가 잘 안 되면 짜증이난다.', key:'41'},
                            {title:'하고 싶은 것을 못하게 하면 저항한다.', key:'42'},
                        ],
                        question6:[
                            {title:'익숙한 것보다 새로운 것을 좋아한다.', key:'85'},
                            {title:'새로운 경험에 경계심 없이 접근한다.', key:'86'},
                            {title:'새로운 상황과 환경을 즐긴다.', key:'87'},
                            {title:'처음 만난 사람도 쉽게 친해진다.', key:'88'},
                            {title:'주변에 관심이 많다.', key:'89'},
                            {title:'새로운 사람을 사귀고 싶어한다.', key:'90'},
                        ],
                        question7:[
                            {title:'관심이 없으면 집중하지 않는다.', key:'67'},
                            {title:'집중 시간이 짧다.', key:'68'},
                            {title:'지시를 들어도 잘 잊어버린다.', key:'69'},
                            {title:'주변 자극에 주의가 쉽게 흐트러진다.', key:'70'},
                            {title:'타인의 말과 행동에 주의를 기울이지 않는다.', key:'71'},
                            {title:'불러도 잘 반응하지 않는다.', key:'72'},
                        ]
                    },
                    {id:2, title:'혼자 상상하고 노는 것이 좋다. 내가 하고 싶은 것만 하고 싶다.',
                        question3:[
                            {title:'호불호가 분명하다.', key:'49'},
                            {title:'지적 욕구가 높다.', key:'50'},
                            {title:'원하는 것을 얻을 때까지 요구한다.', key:'51'},
                            {title:'몰입과 의지력이 강하다.', key:'52'},
                            {title:'감정을 진정하는 시간이 오래 걸린다.', key:'53'},
                            {title:'기억이나 감정을 오래 품고 있다.', key:'54'},
                        ],
                        question4:[
                            {title:'기분이 나쁠 때 쉽게 흥분한다.', key:'37'},
                            {title:'내 뜻대로 되지 않을 때, 금방 화를 낸다.', key:'38'},
                            {title:'작은 불편감에도 신경질을 낸다.', key:'39'},
                            {title:'섭섭하면 화가 난다.', key:'40'},
                            {title:'무엇을 하다가 잘 안 되면 짜증이난다.', key:'41'},
                            {title:'하고 싶은 것을 못하게 하면 저항한다.', key:'42'},
                        ],
                        question5:[
                            {title:'움직임과 활동량이 적다.', key:'7'},
                            {title:'차분한 활동을 좋아한다.', key:'8'},
                            {title:'무엇을 해보고 싶은 동기가 낮다.', key:'9'},
                            {title:'자발적이지 않고 소극적이다.', key:'10'},
                            {title:'주도하고 싶은 마음이 적다.', key:'11'},
                            {title:'다른 사람이 잘하는 것에 관심이 없다.', key:'12'},
                        ],
                        question6:[
                            {title:'관심이 없으면 집중하지 않는다.', key:'67'},
                            {title:'집중 시간이 짧다.', key:'68'},
                            {title:'지시를 들어도 잘 잊어버린다.', key:'69'},
                            {title:'주변 자극에 주의가 쉽게 흐트러진다.', key:'70'},
                            {title:'타인의 말과 행동에 주의를 기울이지 않는다.', key:'71'},
                            {title:'불러도 잘 반응하지 않는다.', key:'72'},
                        ],
                        question7:[
                            {title:'하루의 기분이 대체로 부정적이다.', key:'103'},
                            {title:'감정이 표정으로 드러나지 않는다.', key:'104'},
                            {title:'자주 짜증이나 우울감을 느낀다.', key:'105'},
                            {title:'표정과 감정표현이 건조하다.', key:'106'},
                            {title:'조용히 웃고 운다.', key:'107'},
                            {title:'스트레스를 받을 때 감정전환이 어렵다.', key:'108'},
                        ]
                    },
                    {id:3, title:'내가 하고 싶은 것을 다른 사람들에게 주장하고, 따라와주는 사람들이 좋다.',
                        question3:[
                            {title:'호불호가 분명하다.', key:'49'},
                            {title:'지적 욕구가 높다.', key:'50'},
                            {title:'원하는 것을 얻을 때까지 요구한다.', key:'51'},
                            {title:'몰입과 의지력이 강하다.', key:'52'},
                            {title:'감정을 진정하는 시간이 오래 걸린다.', key:'53'},
                            {title:'기억이나 감정을 오래 품고 있다.', key:'54'},
                        ],
                        question4:[
                            {title:'기분이 나쁠 때 쉽게 흥분한다.', key:'37'},
                            {title:'내 뜻대로 되지 않을 때, 금방 화를 낸다.', key:'38'},
                            {title:'작은 불편감에도 신경질을 낸다.', key:'39'},
                            {title:'섭섭하면 화가 난다.', key:'40'},
                            {title:'무엇을 하다가 잘 안 되면 짜증이난다.', key:'41'},
                            {title:'하고 싶은 것을 못하게 하면 저항한다.', key:'42'},
                        ],
                        question5:[
                            {title:'익숙한 것보다 새로운 것을 좋아한다.', key:'85'},
                            {title:'새로운 경험에 경계심 없이 접근한다.', key:'86'},
                            {title:'새로운 상황과 환경을 즐긴다.', key:'87'},
                            {title:'처음 만난 사람도 쉽게 친해진다.', key:'88'},
                            {title:'주변에 관심이 많다.', key:'89'},
                            {title:'새로운 사람을 사귀고 싶어한다.', key:'90'},
                        ],
                        question6:[
                            {title:'자고 일어나는 시간이 불규칙하다.', key:'31'},
                            {title:'하루 일과가 일관적이지 않다.', key:'32'},
                            {title:'정리정돈을 잘 하지 않는다.', key:'33'},
                            {title:'순응적이지 않다.', key:'34'},
                            {title:'행동과 요구를 예측할 수 없다.', key:'35'},
                            {title:'질서와 순서를 지키기 싫어한다.', key:'36'},
                        ],
                        question7:[
                            {title:'오감이 예민하지 않다.', key:'19'},
                            {title:'쉽게 잠이 들고 잘 잔다.', key:'20'},
                            {title:'환경 변화에 민감하지 않다.', key:'21'},
                            {title:'다른 사람의 불편한 행동에 예민하지 않다.', key:'22'},
                            {title:'소소한 감정변화를 알아차리기 어렵다.', key:'23'},
                            {title:'다른 사람의 표정과 말투변화를 신경쓰지 않는다.', key:'24'},
                        ]
                    },
                    {id:4, title:'나는 내 생각과 의지가 강하고, 혼자 하는 일이 오히려 편하다.',
                        question3:[
                            {title:'움직임과 활동량이 많다.', key:'1'},
                            {title:'움직이는 활동을 좋아한다.', key:'2'},
                            {title:'무엇이든 해보고 싶은 동기가 높다.', key:'3'},
                            {title:'스스로하려는 적극성이 높다.', key:'4'},
                            {title:'주도하고 싶은 마음이 많다.', key:'5'},
                            {title:'다른 사람이 잘하는 것을 부러워한다.', key:'6'},
                        ],
                        question4:[
                            {title:'호불호가 분명하다.', key:'49'},
                            {title:'지적 욕구가 높다.', key:'50'},
                            {title:'원하는 것을 얻을 때까지 요구한다.', key:'51'},
                            {title:'몰입과 의지력이 강하다.', key:'52'},
                            {title:'감정을 진정하는 시간이 오래 걸린다.', key:'53'},
                            {title:'기억이나 감정을 오래 품고 있다.', key:'54'},
                        ],
                        question5:[
                            {title:'기분이 나쁠 때 쉽게 흥분한다.', key:'37'},
                            {title:'내 뜻대로 되지 않을 때, 금방 화를 낸다.', key:'38'},
                            {title:'작은 불편감에도 신경질을 낸다.', key:'39'},
                            {title:'섭섭하면 화가 난다.', key:'40'},
                            {title:'무엇을 하다가 잘 안 되면 짜증이난다.', key:'41'},
                            {title:'하고 싶은 것을 못하게 하면 저항한다.', key:'42'},
                        ],
                        question6:[
                            {title:'밖에 나가는 것을 좋아하지 않는다.', key:'79'},
                            {title:'낯선 장소에 적응하기 어렵다.', key:'80'},
                            {title:'혼자 있기를 좋아한다.', key:'81'},
                            {title:'사람들과 만나면 에너지가 소진된다.', key:'82'},
                            {title:'집단에 적응하는 것이 어렵다.',key:'83'},
                            {title:'사람들과의 관계에서 경계심이 있다.', key:'84'},
                        ],
                        question7:[
                            {title:'굳이 새로운 것을 시도해보지 않는다.', key:'91'},
                            {title:'새로운 경험 하기 전 탐색시간이 길다.', key:'92'},
                            {title:'새로운 환경을 싫어한다.', key:'93'},
                            {title:'주변에 관심이 별로 없다.', key:'94'},
                            {title:'낯선 사람과 익숙해지는데 시간이 오래 걸린다.', key:'95'},
                            {title:'거의 익숙한 사람들과 지낸다.', key:'96'},
                        ]
                    }
                ]
            },
            {id:3, title:'교감과 교류', 
                question2:[
                    {id:1, title:'나는 까다롭고, 뜻대로 안 되면 짜증이 난다. 내가 예민한 건 다 이유가 있다.',
                        question3:[
                            {title:'움직임과 활동량이 많다.', key:'1'},
                            {title:'움직이는 활동을 좋아한다.', key:'2'},
                            {title:'무엇이든 해보고 싶은 동기가 높다.', key:'3'},
                            {title:'스스로하려는 적극성이 높다.', key:'4'},
                            {title:'주도하고 싶은 마음이 많다.', key:'5'},
                            {title:'다른 사람이 잘하는 것을 부러워한다.', key:'6'},
                        ],
                        question4:[
                            {title:'오감이 예민하다.', key:'13'},
                            {title:'자다가 잘 깨고 깊은 수면이 어렵다.', key:'14'},
                            {title:'환경적 변화에 민감하다.', key:'15'},
                            {title:'다른 사람의 불편한 행동에 예민하다.', key:'16'},
                            {title:'다른 사람의 표정과 말투변화에 민감하다.', key:'17'},
                            {title:'섬세한 감정변화를 잘 알아차린다.', key:'18'},
                        ],
                        question5:[
                            {title:'기분이 나쁠 때 쉽게 흥분한다.', key:'37'},
                            {title:'내 뜻대로 되지 않을 때, 금방 화를 낸다.', key:'38'},
                            {title:'작은 불편감에도 신경질을 낸다.', key:'39'},
                            {title:'섭섭하면 화가 난다.', key:'40'},
                            {title:'무엇을 하다가 잘 안 되면 짜증이난다.', key:'41'},
                            {title:'하고 싶은 것을 못하게 하면 저항한다.', key:'42'},
                        ],
                        question6:[
                            {title:'호불호가 분명하다.', key:'49'},
                            {title:'지적 욕구가 높다.', key:'50'},
                            {title:'원하는 것을 얻을 때까지 요구한다.', key:'51'},
                            {title:'몰입과 의지력이 강하다.', key:'52'},
                            {title:'감정을 진정하는 시간이 오래 걸린다.', key:'53'},
                            {title:'기억이나 감정을 오래 품고 있다.', key:'54'},
                        ],
                        question7:[
                            {title:'자고 일어나는 시간이 불규칙하다.', key:'31'},
                            {title:'하루 일과가 일관적이지 않다.', key:'32'},
                            {title:'정리정돈을 잘 하지 않는다.', key:'33'},
                            {title:'순응적이지 않다.', key:'34'},
                            {title:'행동과 요구를 예측할 수 없다.', key:'35'},
                            {title:'질서와 순서를 지키기 싫어한다.', key:'36'},
                        ]
                    },
                    {id:2, title:'나는 감정에 예민한데 감정을 생각하기보다는 편하게 쉬고 싶어 한다.',
                        question3:[
                            {title:'오감이 예민하다.', key:'13'},
                            {title:'자다가 잘 깨고 깊은 수면이 어렵다.', key:'14'},
                            {title:'환경적 변화에 민감하다.', key:'15'},
                            {title:'다른 사람의 불편한 행동에 예민하다.', key:'16'},
                            {title:'다른 사람의 표정과 말투변화에 민감하다.', key:'17'},
                            {title:'섬세한 감정변화를 잘 알아차린다.', key:'18'},
                        ],
                        question4:[
                            {title:'기분이 나쁠 때 쉽게 흥분한다.', key:'37'},
                            {title:'내 뜻대로 되지 않을 때, 금방 화를 낸다.', key:'38'},
                            {title:'작은 불편감에도 신경질을 낸다.', key:'39'},
                            {title:'섭섭하면 화가 난다.', key:'40'},
                            {title:'무엇을 하다가 잘 안 되면 짜증이난다.', key:'41'},
                            {title:'하고 싶은 것을 못하게 하면 저항한다.', key:'42'},
                        ],
                        question5:[
                            {title:'움직임과 활동량이 적다.', key:'7'},
                            {title:'차분한 활동을 좋아한다.', key:'8'},
                            {title:'무엇을 해보고 싶은 동기가 낮다.', key:'9'},
                            {title:'자발적이지 않고 소극적이다.', key:'10'},
                            {title:'주도하고 싶은 마음이 적다.', key:'11'},
                            {title:'다른 사람이 잘하는 것에 관심이 없다.', key:'12'},
                        ],
                        question6:[
                            {title:'관심이 없으면 집중하지 않는다.', key:'67'},
                            {title:'집중 시간이 짧다.', key:'68'},
                            {title:'지시를 들어도 잘 잊어버린다.', key:'69'},
                            {title:'주변 자극에 주의가 쉽게 흐트러진다.', key:'70'},
                            {title:'타인의 말과 행동에 주의를 기울이지 않는다.', key:'71'},
                            {title:'불러도 잘 반응하지 않는다.', key:'72'},
                        ],
                        question7:[
                            {title:'하루의 기분이 대체로 부정적이다.', key:'103'},
                            {title:'감정이 표정으로 드러나지 않는다.', key:'104'},
                            {title:'자주 짜증이나 우울감을 느낀다.', key:'105'},
                            {title:'표정과 감정표현이 건조하다.', key:'106'},
                            {title:'조용히 웃고 운다.', key:'107'},
                            {title:'스트레스를 받을 때 감정전환이 어렵다.', key:'108'},
                        ]
                    },
                    {id:3, title:'사람들과 어울리는 것을 좋아한다. 그러나 그만큼 사람들과 갈등이나 속상함도 많다.',
                        question3:[
                            {title:'오감이 예민하다.', key:'13'},
                            {title:'자다가 잘 깨고 깊은 수면이 어렵다.', key:'14'},
                            {title:'환경적 변화에 민감하다.', key:'15'},
                            {title:'다른 사람의 불편한 행동에 예민하다.', key:'16'},
                            {title:'다른 사람의 표정과 말투변화에 민감하다.', key:'17'},
                            {title:'섬세한 감정변화를 잘 알아차린다.', key:'18'},
                        ],
                        question4:[
                            {title:'기분이 나쁠 때 쉽게 흥분한다.', key:'37'},
                            {title:'내 뜻대로 되지 않을 때, 금방 화를 낸다.', key:'38'},
                            {title:'작은 불편감에도 신경질을 낸다.', key:'39'},
                            {title:'섭섭하면 화가 난다.', key:'40'},
                            {title:'무엇을 하다가 잘 안 되면 짜증이난다.', key:'41'},
                            {title:'하고 싶은 것을 못하게 하면 저항한다.', key:'42'},
                        ],
                        question5:[
                            {title:'익숙한 것보다 새로운 것을 좋아한다.', key:'85'},
                            {title:'새로운 경험에 경계심 없이 접근한다.', key:'86'},
                            {title:'새로운 상황과 환경을 즐긴다.', key:'87'},
                            {title:'처음 만난 사람도 쉽게 친해진다.', key:'88'},
                            {title:'주변에 관심이 많다.', key:'89'},
                            {title:'새로운 사람을 사귀고 싶어한다.', key:'90'},
                        ],
                        question6:[
                            {title:'바깥 활동을 좋아한다.', key:'73'},
                            {title:'낯선 장소에 유연하게 적응한다.', key:'74'},
                            {title:'집단에 적응을 잘한다.', key:'75'},
                            {title:'많은 사람들과 어울리기를 좋아한다.', key:'76'},
                            {title:'사람들을 만나면 에너지가 생긴다.', key:'77'},
                            {title:'사람들과 친밀한 관계를 형성한다.', key:'78'},
                        ],
                        question7:[
                            {title:'자고 일어나는 시간이 불규칙하다.', key:'31'},
                            {title:'하루 일과가 일관적이지 않다.', key:'32'},
                            {title:'정리정돈을 잘 하지 않는다.', key:'33'},
                            {title:'순응적이지 않다.', key:'34'},
                            {title:'행동과 요구를 예측할 수 없다.', key:'35'},
                            {title:'질서와 순서를 지키기 싫어한다.', key:'36'},
                        ]
                    },
                    {id:4, title:'나는 생각과 감정이 모두 민감하고 쉽게 불안해지는 편이다. 그래서 적응하는 것이 쉽지 않다.',
                        question3:[
                            {title:'오감이 예민하다.', key:'13'},
                            {title:'자다가 잘 깨고 깊은 수면이 어렵다.', key:'14'},
                            {title:'환경적 변화에 민감하다.', key:'15'},
                            {title:'다른 사람의 불편한 행동에 예민하다.', key:'16'},
                            {title:'다른 사람의 표정과 말투변화에 민감하다.', key:'17'},
                            {title:'섬세한 감정변화를 잘 알아차린다.', key:'18'},
                        ],
                        question4:[
                            {title:'기분이 나쁠 때 쉽게 흥분한다.', key:'37'},
                            {title:'내 뜻대로 되지 않을 때, 금방 화를 낸다.', key:'38'},
                            {title:'작은 불편감에도 신경질을 낸다.', key:'39'},
                            {title:'섭섭하면 화가 난다.', key:'40'},
                            {title:'무엇을 하다가 잘 안 되면 짜증이난다.', key:'41'},
                            {title:'하고 싶은 것을 못하게 하면 저항한다.', key:'42'},
                        ],
                        question5:[
                            {title:'굳이 새로운 것을 시도해보지 않는다.', key:'91'},
                            {title:'새로운 경험 하기 전 탐색시간이 길다.', key:'92'},
                            {title:'새로운 환경을 싫어한다.', key:'93'},
                            {title:'주변에 관심이 별로 없다.', key:'94'},
                            {title:'낯선 사람과 익숙해지는데 시간이 오래 걸린다.', key:'95'},
                            {title:'거의 익숙한 사람들과 지낸다.', key:'96'},
                        ],
                        question6:[
                            {title:'하루의 기분이 대체로 부정적이다.', key:'103'},
                            {title:'감정이 표정으로 드러나지 않는다.', key:'104'},
                            {title:'자주 짜증이나 우울감을 느낀다.', key:'105'},
                            {title:'표정과 감정표현이 건조하다.', key:'106'},
                            {title:'조용히 웃고 운다.', key:'107'},
                            {title:'스트레스를 받을 때 감정전환이 어렵다.', key:'108'},
                        ],
                        question7:[
                            {title:'밖에 나가는 것을 좋아하지 않는다.', key:'79'},
                            {title:'낯선 장소에 적응하기 어렵다.', key:'80'},
                            {title:'혼자 있기를 좋아한다.', key:'81'},
                            {title:'사람들과 만나면 에너지가 소진된다.', key:'82'},
                            {title:'집단에 적응하는 것이 어렵다.',key:'83'},
                            {title:'사람들과의 관계에서 경계심이 있다.', key:'84'},
                        ]
                    }
                ]
            },
            {id:4, title:'경험과 즐거움', 
                question2:[
                    {id:1, title:'뭐든 즐겁기만 하다면 다 좋아한다. 그리고 복잡하게 생각하지 않는 편이다.',
                        question3:[
                            {title:'움직임과 활동량이 많다.', key:'1'},
                            {title:'움직이는 활동을 좋아한다.', key:'2'},
                            {title:'무엇이든 해보고 싶은 동기가 높다.', key:'3'},
                            {title:'스스로하려는 적극성이 높다.', key:'4'},
                            {title:'주도하고 싶은 마음이 많다.', key:'5'},
                            {title:'다른 사람이 잘하는 것을 부러워한다.', key:'6'},
                        ],
                        question4:[
                            {title:'바깥 활동을 좋아한다.', key:'73'},
                            {title:'낯선 장소에 유연하게 적응한다.', key:'74'},
                            {title:'집단에 적응을 잘한다.', key:'75'},
                            {title:'많은 사람들과 어울리기를 좋아한다.', key:'76'},
                            {title:'사람들을 만나면 에너지가 생긴다.', key:'77'},
                            {title:'사람들과 친밀한 관계를 형성한다.', key:'78'},
                        ],
                        question5:[
                            {title:'자고 일어나는 시간이 규칙적이다.', key:'25'},
                            {title:'하루 일과가 일관적이다.', key:'27'},
                            {title:'정리정돈을 잘한다.', key:'28'},
                            {title:'순응적이다.', key:'29'},
                            {title:'행동과 요구가 예측 가능하다.', key:'30'},
                            {title:'질서와 순서를 확실하게 지킨다.', key:'31'},
                        ],
                        question6:[
                            {title:'하루의 기분이 대체로 긍정적이다.', key:'97'},
                            {title:'감정이 표정으로 잘 드러난다.', key:'98'},
                            {title:'긍정적 기분과 감정을 자주 느낀다.', key:'99'},
                            {title:'표정과 감정표현이 다양하다.', key:'100'},
                            {title:'크게 소리내어웃거나 운다.', key:'101'},
                            {title:'스트레스를 받을 때 감정전환이 빠르다.', key:'102'},
                        ],
                        question7:[
                            {title:'오감이 예민하지 않다.', key:'19'},
                            {title:'쉽게 잠이 들고 잘 잔다.', key:'20'},
                            {title:'환경 변화에 민감하지 않다.', key:'21'},
                            {title:'다른 사람의 불편한 행동에 예민하지 않다.', key:'22'},
                            {title:'소소한 감정변화를 알아차리기 어렵다.', key:'23'},
                            {title:'다른 사람의 표정과 말투변화를 신경쓰지 않는다.', key:'24'},
                        ]
                    },
                    {id:2, title:'대부분 내 생각을 말하기보다는 편안하게 어울리는 편이다. 좋고 싫은 것을 잘 드러내지 않는다.',
                        question3:[
                            {title:'바깥 활동을 좋아한다.', key:'73'},
                            {title:'낯선 장소에 유연하게 적응한다.', key:'74'},
                            {title:'집단에 적응을 잘한다.', key:'75'},
                            {title:'많은 사람들과 어울리기를 좋아한다.', key:'76'},
                            {title:'사람들을 만나면 에너지가 생긴다.', key:'77'},
                            {title:'사람들과 친밀한 관계를 형성한다.', key:'78'},
                        ],
                        question4:[
                            {title:'자고 일어나는 시간이 규칙적이다.', key:'25'},
                            {title:'하루 일과가 일관적이다.', key:'27'},
                            {title:'정리정돈을 잘한다.', key:'28'},
                            {title:'순응적이다.', key:'29'},
                            {title:'행동과 요구가 예측 가능하다.', key:'30'},
                            {title:'질서와 순서를 확실하게 지킨다.', key:'31'},
                        ],
                        question5:[
                            {title:'움직임과 활동량이 적다.', key:'7'},
                            {title:'차분한 활동을 좋아한다.', key:'8'},
                            {title:'무엇을 해보고 싶은 동기가 낮다.', key:'9'},
                            {title:'자발적이지 않고 소극적이다.', key:'10'},
                            {title:'주도하고 싶은 마음이 적다.', key:'11'},
                            {title:'다른 사람이 잘하는 것에 관심이 없다.', key:'12'},
                        ],
                        question6:[
                            {title:'관심이 없으면 집중하지 않는다.', key:'67'},
                            {title:'집중 시간이 짧다.', key:'68'},
                            {title:'지시를 들어도 잘 잊어버린다.', key:'69'},
                            {title:'주변 자극에 주의가 쉽게 흐트러진다.', key:'70'},
                            {title:'타인의 말과 행동에 주의를 기울이지 않는다.', key:'71'},
                            {title:'불러도 잘 반응하지 않는다.', key:'72'},
                        ],
                        question7:[
                            {title:'기분이 나쁠 때 흥분하지 않는다.', key:'43'},
                            {title:'내 뜻대로 되지 않을 때 참거나 넘긴다.', key:'44'},
                            {title:'작은 불편감들은 표현하지 않는다.', key:'45'},
                            {title:'섭섭해도 잘 표현하지 않는다.', key:'46'},
                            {title:'짜증감이 쌓이다가 힘들면 분출된다.', key:'47'},
                            {title:'화가 나도 불편한 마음을 참는다.', key:'68'},
                        ]
                    },
                    {id:3, title:'사람들과 함께 놀때가 제일 신나고 즐겁다. 그렇지만 흥미가 없는 것을 하는 것은 오래 하지 못한다.',
                        question3:[
                            {title:'바깥 활동을 좋아한다.', key:'73'},
                            {title:'낯선 장소에 유연하게 적응한다.', key:'74'},
                            {title:'집단에 적응을 잘한다.', key:'75'},
                            {title:'많은 사람들과 어울리기를 좋아한다.', key:'76'},
                            {title:'사람들을 만나면 에너지가 생긴다.', key:'77'},
                            {title:'사람들과 친밀한 관계를 형성한다.', key:'78'},
                        ],
                        question4:[
                            {title:'익숙한 것보다 새로운 것을 좋아한다.', key:'85'},
                            {title:'새로운 경험에 경계심 없이 접근한다.', key:'86'},
                            {title:'새로운 상황과 환경을 즐긴다.', key:'87'},
                            {title:'처음 만난 사람도 쉽게 친해진다.', key:'88'},
                            {title:'주변에 관심이 많다.', key:'89'},
                            {title:'새로운 사람을 사귀고 싶어한다.', key:'90'},
                        ],
                        question5:[
                            {title:'하루의 기분이 대체로 긍정적이다.', key:'97'},
                            {title:'감정이 표정으로 잘 드러난다.', key:'98'},
                            {title:'긍정적 기분과 감정을 자주 느낀다.', key:'99'},
                            {title:'표정과 감정표현이 다양하다.', key:'100'},
                            {title:'크게 소리내어웃거나 운다.', key:'101'},
                            {title:'스트레스를 받을 때 감정전환이 빠르다.', key:'102'},
                        ],
                        question6:[
                            {title:'관심이 없으면 집중하지 않는다.', key:'67'},
                            {title:'집중 시간이 짧다.', key:'68'},
                            {title:'지시를 들어도 잘 잊어버린다.', key:'69'},
                            {title:'주변 자극에 주의가 쉽게 흐트러진다.', key:'70'},
                            {title:'타인의 말과 행동에 주의를 기울이지 않는다.', key:'71'},
                            {title:'불러도 잘 반응하지 않는다.', key:'72'},
                        ],
                        question7:[
                            {title:'호불호가 분명하지 않다.', key:'55'},
                            {title:'지적 욕구가 높지 않다.', key:'56'},
                            {title:'원하는 것이 안되면 쉽게 포기한다.', key:'57'},
                            {title:'선택과 몰입이 약하다.', key:'58'},
                            {title:'감정을 진정하는 시간이 짧다.', key:'59'},
                            {title:'기억이나 감정을 금세 잊는다.', key:'60'},
                        ]
                    },
                    {id:4, title:'혼자 편하게 있는 것을 좋아한다. 그래도 막상 어울려야 하면 예민하지 않아서 잘 어울린다.',
                        question3:[
                            {title:'주의 깊게 잘 듣고 반응한다.', key:'61'},
                            {title:'지시를 명확하게 듣고 반응한다.', key:'62'},
                            {title:'관심이 없어도 집중한다.', key:'63'},
                            {title:'주변 자극에도 주의를 유지한다.', key:'64'},
                            {title:'집중 시간이 길다.', key:'65'},
                            {title:'타인의 말과 행동에 주의를 기울인다.', key:'66'},
                        ],
                        question4:[
                            {title:'자고 일어나는 시간이 규칙적이다.', key:'25'},
                            {title:'하루 일과가 일관적이다.', key:'27'},
                            {title:'정리정돈을 잘한다.', key:'28'},
                            {title:'순응적이다.', key:'29'},
                            {title:'행동과 요구가 예측 가능하다.', key:'30'},
                            {title:'질서와 순서를 확실하게 지킨다.', key:'31'},
                        ],
                        question5:[
                            {title:'밖에 나가는 것을 좋아하지 않는다.', key:'79'},
                            {title:'낯선 장소에 적응하기 어렵다.', key:'80'},
                            {title:'혼자 있기를 좋아한다.', key:'81'},
                            {title:'사람들과 만나면 에너지가 소진된다.', key:'82'},
                            {title:'집단에 적응하는 것이 어렵다.',key:'83'},
                            {title:'사람들과의 관계에서 경계심이 있다.', key:'84'},
                        ],
                        question6:[
                            {title:'하루의 기분이 대체로 부정적이다.', key:'103'},
                            {title:'감정이 표정으로 드러나지 않는다.', key:'104'},
                            {title:'자주 짜증이나 우울감을 느낀다.', key:'105'},
                            {title:'표정과 감정표현이 건조하다.', key:'106'},
                            {title:'조용히 웃고 운다.', key:'107'},
                            {title:'스트레스를 받을 때 감정전환이 어렵다.', key:'108'},
                        ],
                        question7:[
                            {title:'굳이 새로운 것을 시도해보지 않는다.', key:'91'},
                            {title:'새로운 경험 하기 전 탐색시간이 길다.', key:'92'},
                            {title:'새로운 환경을 싫어한다.', key:'93'},
                            {title:'주변에 관심이 별로 없다.', key:'94'},
                            {title:'낯선 사람과 익숙해지는데 시간이 오래 걸린다.', key:'95'},
                            {title:'거의 익숙한 사람들과 지낸다.', key:'96'},
                        ]
                    }
                ]
            },
        ],
    }
  },
  mounted() {
    document.body.classList.add('no-floting');
  },
  unmounted() {
    document.body.classList.remove('no-floting');
  },
  methods:{
    questionPrev(){
        if(this.barCount <= 1){
            return false
        }else{
            this.question --;
            this.barLine = this.barLine - 12.5;
            this.barCount --;
        }
    },
    questionNext(){
        if(this.barCount >= 8){
            return false
        }else{
            this.question ++;
            this.barLine = this.barLine + 12.5;
            this.barCount ++;
            this.disabled = true;
        }
    },
    //체크박스 체크
    checkChange(event){
        var selected = event.target.value;
        this.checkValue = selected;
        const items = document.querySelectorAll('[name="age"]:checked');
        if(items.length > 0){
            this.disabled = false;
        }else{
            this.disabled = true;
        }

    },
    questChange1(i){
        this.checkIndex1 = i + 1
        const items = document.querySelectorAll('[name="Temp1"]:checked');
        if(items.length > 0){
            this.disabled = false;
        }else{
            this.disabled = true;
        }
    },
    questChange2(i){
        this.checkIndex2 = i + 1
        const items = document.querySelectorAll('[name="Temp2"]:checked');
        if(items.length > 0){
            this.disabled = false;
        }else{
            this.disabled = true;
        }

        if(this.checkIndex1 == 1){
            this.resultNum = this.checkIndex1 + this.checkIndex2 - 1
        }else{
            this.resultNum = this.checkIndex1 * 4 - 4 + this.checkIndex2
        }

        if(this.resultNum == 1){
            this.formTitle = '다양한 행동형 관용기질';
        }

        if(this.resultNum == 2){
            this.formTitle = '선택적 행동형 관용기질';
        }

        if(this.resultNum == 3){
            this.formTitle = '다양한 연대형 관용기질';
        }

        if(this.resultNum == 4){
            this.formTitle = '선택적 연대형 관용기질';
        }

        if(this.resultNum == 5){
            this.formTitle = '다양한 행동형 몰입기질';
        }

        if(this.resultNum == 6){
            this.formTitle = '선택적 행동형 몰입기질';
        }

        if(this.resultNum == 7){
            this.formTitle = '다양한 연대형 몰입기질';
        }

        if(this.resultNum == 8){
            this.formTitle = '선택적 연대형 몰입기질';
        }

        if(this.resultNum == 9){
            this.formTitle = '다양한 행동형 교감기질';
        }

        if(this.resultNum == 10){
            this.formTitle = '선택적 행동형 교감기질';
        }

        if(this.resultNum == 11){
            this.formTitle = '다양한 연대형 교감기질';
        }

        if(this.resultNum == 12){
            this.formTitle = '선택적 연대형 교감기질';
        }

        if(this.resultNum == 13){
            this.formTitle = '다양한 행동형 포용기질';
        }

        if(this.resultNum == 14){
            this.formTitle = '선택적 행동형 포용기질';
        }

        if(this.resultNum == 15){
            this.formTitle = '다양한 연대형 포용기질';
        }

        if(this.resultNum == 16){
            this.formTitle = '선택적 연대형 포용기질';
        }
    },
    questChange3(i){
        this.checkIndex3 = i + 1
        const items = document.querySelectorAll('[name="Temp3"]:checked');
        if(items.length > 0){
            this.disabled = false;
        }else{
            this.disabled = true;
        }
        this.checkIndex3Key = this.questPost[this.checkIndex1 - 1].question2[this.checkIndex2 - 1].question3[i].key
    },
    questChange4(i){
        this.checkIndex4 = i + 1
        const items = document.querySelectorAll('[name="Temp4"]:checked');
        if(items.length > 0){
            this.disabled = false;
        }else{
            this.disabled = true;
        }

        this.checkIndex4Key = this.questPost[this.checkIndex1 - 1].question2[this.checkIndex2 - 1].question4[i].key
    },
    questChange5(i){
        this.checkIndex5 = i + 1
        const items = document.querySelectorAll('[name="Temp5"]:checked');
        if(items.length > 0){
            this.disabled = false;
        }else{
            this.disabled = true;
        }

        this.checkIndex5Key = this.questPost[this.checkIndex1 - 1].question2[this.checkIndex2 - 1].question5[i].key
    },
    questChange6(i){
        this.checkIndex6 = i + 1
        const items = document.querySelectorAll('[name="Temp6"]:checked');
        if(items.length > 0){
            this.disabled = false;
        }else{
            this.disabled = true;
        }

        this.checkIndex6Key = this.questPost[this.checkIndex1 - 1].question2[this.checkIndex2 - 1].question6[i].key
    },
    questChange7(i){
        this.checkIndex7 = i + 1
        const items = document.querySelectorAll('[name="Temp7"]:checked');
        if(items.length > 0){
            this.disabled = false;
        }else{
            this.disabled = true;
        }

        this.checkIndex7Key = this.questPost[this.checkIndex1 - 1].question2[this.checkIndex2 - 1].question7[i].key
    },

    //발송 여부
    formResult(){
        this.formCard.push(this.checkIndex3Key);
        this.formCard.push(this.checkIndex4Key);
        this.formCard.push(this.checkIndex5Key);
        this.formCard.push(this.checkIndex6Key);
        this.formCard.push(this.checkIndex7Key);
        var url = 'https://withyoulove.co.kr:8000/api/resultAdd/';
        var data = {
            select: this.resultNum,
            title: this.formTitle,
            card: this.formCard,
        }
        axios.post(url, data)
            .then(function(response){
                let resultId = response.data.id;
                window.location = '/TestResult/' + resultId;
        })
            .catch(function(error){
            console.log(error);
        });
    },
  }
}
</script>
