<template> 
    <!-- AboutTab -->
    <div class="AboutTab">
        <div class="site-container">    
            <ul class="AboutTab-list">
                <li class="AboutTab-item" :class="[isCurrent === '1' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'Temperament'}" class="AboutTab-link">기질 평가 프로그램</router-link>
                </li>
                <li class="AboutTab-item" :class="[isCurrent === '2' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'Psychotherapy'}" class="AboutTab-link">심리치료 및 상담</router-link>
                </li>
                <li class="AboutTab-item" :class="[isCurrent === '3' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'Special'}" class="AboutTab-link">특별프로그램</router-link>
                </li>
                <li class="AboutTab-item" :class="[isCurrent === '4' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'PrivateQualification'}" class="AboutTab-link">민간자격 프로그램</router-link>
                </li>
                <li class="AboutTab-item" :class="[isCurrent === '5' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'WisdomOfParenting'}" class="AboutTab-link">양육의 지혜 프로그램</router-link>
                </li>
            </ul>
        </div>
    </div> 
    <!-- .AboutTab -->

</template>
<script>
export default {
    props:['isCurrent']
}
</script>