<template>
    <div class="PostPaing">
        <ul class="PostPaing-list">
            <li class="PostPaing-item btn-First">
                <a :disabled="num === 1" @click="firstPage" href="javascript:void(0)" class="PostPaing-link"><span class="ab-text">처음</span></a>
            </li>
            <li class="PostPaing-item btn-Prev">
                <a :disabled="num === 1" @click="prevPage" href="javascript:void(0)" class="PostPaing-link"><span class="ab-text">이전</span></a>
            </li>
            <li class="PostPaing-item" v-for="i in pages" :key="i">
                <span v-if="i === num" class="PostPaing-link is-Current">{{i}}</span>
                <a v-else href="javascript:void(0)" @click="itemPage(i)"  class="PostPaing-link">{{i}}</a>
            </li>
            <li class="PostPaing-item btn-Next">
                <a :disabled="num === totalPageCount" @click="nextPage" href="javascript:void(0)" class="PostPaing-link"><span class="ab-text">다음</span></a>
            </li>
            <li class="PostPaing-item btn-Last">
                <a :disabled="num === totalPageCount" @click="lastPage" href="javascript:void(0)" class="PostPaing-link"><span class="ab-text">마지막</span></a>
            </li>
        </ul>
    </div>
</template>
<script>

export default {
    name:'post-paging',
    emits: ["itemUpdate"],
    props: ['totalPageCount'],
    data(){
        return{
            num:1,
        }
    },
    mounted() {
        if(this.$route.query.page){
            this.num = parseInt(this.$route.query.page);
        }
    },
    computed: {
        pages: function() {
            const list = [];
            for (let index = this.startPage; index <= this.endPage; index += 1) { list.push(index); }
            return list;
        },
        startPage() {
            return parseInt((this.num - 1) / 10) * 10 + 1;
        },
        endPage() {
            let lastPage = parseInt((this.num - 1) / 10) * 10 + 10;
            return lastPage <= this.totalPageCount ? lastPage : this.totalPageCount;
        }
    },
    methods: {
        itemPage (i) {
            this.$emit('itemUpdate', i);
        },
        firstPage(){
            this.$emit('firstUpdate', this.num = 1);
        },
        prevPage(){
            this.$emit('prevUpdate', this.num --);
        },
        nextPage(){
            this.$emit('nextUpdate', this.num ++);
        },
        lastPage(){
            this.$emit('lastUpdate', this.totalPageCount);
        },
    },
}
</script>