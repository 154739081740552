<template>
  <div class="site-container">
    <div class="PagePolicy">
      <h3 class="PageTitle">개인정보처리방침</h3>
      <div class="PagePolicyCont">
        <p class="BsText mb45">‘위드유 치료교육연구소’는 (이하 ‘회사’)는 정보주체의 개인정보를 소중하게 생각하며 “개인정보보호법”등 관련 법률을 준수하고 있습니다. 회사는 &lt;개인정보처리방침&gt;을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</p>
        <h4 class="BsTitle mb10">제1조 총칙</h4>
        <p class="BsText mb40">“개인정보”란 살아 있는 개인에 관한 정보로서 성명, 주민등록번호 및 영상 등을 통하여 개인을 알아볼 수 있는 정보 (해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 정보. 이경우 쉽게 결합할 수 있는지 여부는 다른 정보의 입수 가능성 등 개인을 알아보는 데 소요되는 시간, 비용, 기술 등을 합리적으로 고려하여야 합니다.)를 말합니다. “정보주체” 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는 사람을 말합니다.</p>

        <h4 class="BsTitle mb10">제2조 개인정보의 수집 항목 및 수집방법</h4>
        <p class="BsText mb20">회사는 서비스 제공을 위해 필요한 최소한의 범위 내에서 개인정보를 수집하고 있습니다.</p>
        <h5 class="SmallTitle mb10">1. 고객문의</h5>
        <ul class="mb20">
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">성명, 연락처, E-Mail</span>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">아동에 대한 평가프로그램 신청 시 : 성명, 연락처, E-Mail, 아동 성명</span>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">강의신청 항목 선택 시 : 기관명, 담당자명, 연락처, 이메일, 강의요청일자, 강의시간, 강의요청내용</span>
            </div>
          </li>
        </ul>
        <h5 class="SmallTitle mb10">2. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.</h5>
        <ul class="mb20">
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">IP 주소, 서비스 이용기록, 방문기록, 쿠키</span>
            </div>
          </li>
        </ul>
        <h5 class="SmallTitle mb40">3. 예약하기 관련된 개인정보 수집 및 처리는 예약 페이지에 개인정보처리방침을 통해 확인할 수 있습니다.</h5>
        
        <h4 class="BsTitle mb10">제3조 개인정보의 처리 목적</h4>
        <h5 class="SmallTitle mb10">1. 고객문의</h5>
        <ul class="mb40">
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">사용자 식별, 문의대응, 제안ᆞ불만 등의 민원처리</span>
            </div>
          </li>
        </ul>
        <h4 class="BsTitle mb10">제4조 개인정보의 처리 및 보유 기간</h4>
        <h5 class="SmallTitle mb10">1. 고객문의</h5>
        <ul class="mb40">
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">프로그램 진행 완료 후 즉시 파기</span>
            </div>
          </li>
        </ul>
        <h4 class="BsTitle mb10">제5조 개인정보의 파기</h4>
        <p class="BsText mb40">회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</p>
        <h5 class="SmallTitle mb10">1. 파기절차</h5>
        <ul class="mb40">
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">회사는 파기 사유가 발생한 개인정보를 선정하고 회사의 개인정보보호 책임자의 승인을 받아 개인정보를 파기합니다.</span>
            </div>
          </li>
        </ul>
        <h5 class="SmallTitle mb10">2. 파기방법</h5>
        <ul class="mb40">
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</span>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 완전 삭제합니다.</span>
            </div>
          </li>
        </ul>
        <h4 class="BsTitle mb10">제6조 개인정보의 제 3자 제공</h4>
        <p class="BsText mb40">회사는 홈페이지에서 개인정보를 제3자에게 제공하지 않습니다.</p>
        <h4 class="BsTitle mb10">제7조 개인정보의 처리위탁</h4>
        <p class="BsText mb40">회사는 홈페이지 관련 개인정보 처리 위탁 업무가 없습니다.<br />만약 위탁업무가 발생할 경우 지체없이 본 개인정보처리방침을 통하여 공개하도록 하겠습니다.<br />제8조 정보주체의 권리∙의무 및 행사방법 정보주체는 회사에 대해 언제든지 다음의 개인정보 보호 관련 권리를 행사할 수 있습니다.</p>
        <h5 class="SmallTitle mb10">1. 개인정보 열람요구</h5>
        <h5 class="SmallTitle mb10">2. 오류 등이 있을 경우 정정 요구</h5>
        <h5 class="SmallTitle mb10">3. 삭제 요구</h5>
        <h5 class="SmallTitle mb10">4. 처리정지 요구</h5>
        <p class="BsText mb40">개인 정보 보호 관련 권리 행사는 회사에 대해 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br />회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 ‘제4조 개인정보의 처리 및 보유기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다. 또한 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 위임장을 제출하셔야 합니다.</p>
        <h4 class="BsTitle mb10">제9조 개인정보의 안전성 확보조치</h4>
        <p class="BsText mb40"><strong>회사는 정보주체의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 조치를 취하고 있습니다.</strong></p>
        <ul class="mb40">
          <li>
            <div class="BsBullet">
              <div class="BsBullet-bullet">•</div>
              <div class="BsBullet-text">
                <div><strong>관리적 조치</strong></div>
                <div class="BsBullet">
                  <span class="BsBullet-bullet">-</span>
                  <span class="BsBullet-text">회사는 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하여 정책의 준수를 강조하고 있으며, 이와 관련된 문제가 발견될 경우 바로 수정하고 바로 잡을 수 있도록 노력하고 있습니다. </span>
                </div>                
              </div>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <div class="BsBullet-bullet">•</div>
              <div class="BsBullet-text">
                <div><strong>기술적 조치</strong></div>
                <div class="BsBullet">
                  <span class="BsBullet-bullet">-</span>
                  <span class="BsBullet-text">개인정보는 비밀번호에 의해 보호되며, 중요한 데이터는 별도의 보안(암호화)기능을 통해 보호되고 있습니다.</span>
                </div>                
                <div class="BsBullet">
                  <span class="BsBullet-bullet">-</span>
                  <span class="BsBullet-text">백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다.</span>
                </div>                
                <div class="BsBullet">
                  <span class="BsBullet-bullet">-</span>
                  <span class="BsBullet-text">회사는 해킹 등 외부침입에 대비하여 침입차단시스템을 운영하고 있습니다.</span>
                </div>                
              </div>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <div class="BsBullet-bullet">•</div>
              <div class="BsBullet-text">
                <div><strong>물리적 조치</strong></div>
                <div class="BsBullet">
                  <span class="BsBullet-bullet">-</span>
                  <span class="BsBullet-text">집된 개인정보는 내부관리 계획에 근거하여 물리적 접근을 제한하여 보관하고 있습니다.</span>
                </div>             
              </div>
            </div>
          </li>
        </ul>
        <h4 class="BsTitle mb10">제10조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h4>
        <p class="BsText mb40">회사는 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
        <h5 class="SmallTitle mb10">쿠키의 사용목적</h5>
        <ul class="mb40">
          <li>
            <div class="BsBullet">
              <div class="BsBullet">
                <span class="BsBullet-bullet">-</span>
                <span class="BsBullet-text">세션 관리 및 편의 기능 제공을 위해 사용됩니다.</span>
              </div>    
            </div>
          </li>
        </ul>
        <h5 class="SmallTitle mb10">쿠키의 설치·운영 및 거부</h5>
        <ul class="mb40">
          <li>
            <div class="BsBullet">
              <div class="BsBullet">
                <span class="BsBullet-bullet">-</span>
                <span class="BsBullet-text">이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정 함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우 회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다.</span>
              </div>    
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <div class="BsBullet-bullet">-</div>
              <div class="BsBullet-text">
                <div>쿠키 설치 허용 여부를 지정하는 방법은 다음과 같습니다.</div>
                <div class="BsBullet">
                  <span class="BsBullet-bullet">가. </span>
                  <span class="BsBullet-text">Internet Explorer: [도구] → [인터넷 옵션] → [개인정보]탭 → [고급] 변경</span>
                </div>             
                <div class="BsBullet">
                  <span class="BsBullet-bullet">나. </span>
                  <span class="BsBullet-text">Chrome: [설정] → [보안 및 개인정보 보호] → [쿠키 및 기타 사이트 데이터] 설정</span>
                </div>             
              </div>
            </div>
          </li>
        </ul>
        <h4 class="BsTitle mb10">제11조 개인정보 보호책임자 및 담당자의 연락처</h4>
        <p class="BsText mb10">회사는 개인정보를 보호하고 개인정보 처리와 관련한 불만처리를 위하여 다음과 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
        <p class="BsText mb10">문의하기 페이지 개인정보보호 책임자</p>
        <ul class="mb40">
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">- 이름 :</span>
              <span class="BsBullet-text">신두한</span>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">- 직책 :</span>
              <span class="BsBullet-text">사무국장</span>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">- 연락처 :</span>
              <span class="BsBullet-text">010-2045-1028</span>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">- 이메일 :</span>
              <span class="BsBullet-text"><a class="BsLink" target="_blank" href="mailto:withyoulove1225@naver.com">withyoulove1225@naver.com</a></span>
            </div>
          </li>
        </ul>
        <p class="BsText mb10">정보주체는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.<br />회사는 정보주체의 문의에 대해 신속·정확한 답변을 드리겠습니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
        <ul class="mb40">
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">개인정보 침해신고센터 (<a class="BsLink" target="_blank" href="https://privacy.kisa.or.kr/main.do">privacy.kisa.or.kr</a> / 전화:118)</span>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">개인정보 분쟁조정위원회 (<a class="BsLink" target="_blank" href="https://www.kopico.go.kr/main/main.do">www.kopico.go.kr</a> / 전화:1833-6972)</span>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">대검찰청 사이버수사과 (<a class="BsLink" target="_blank" href="https://www.spo.go.kr/site/spo/main.do">www.spo.go.kr</a> / 전화:1301)</span>
            </div>
          </li>
          <li>
            <div class="BsBullet">
              <span class="BsBullet-bullet">-</span>
              <span class="BsBullet-text">경찰청 사이버안전국 (<a class="BsLink" target="_blank" href="https://cyberbureau.police.go.kr/">cyberbureau.police.go.kr</a> / 전화:182)</span>
            </div>
          </li>
        </ul>
        <h4 class="BsTitle mb10">제12조 개인정보처리방침 변경</h4>
        <p class="BsText">이 개인정보처리방침은 2023년 00월 00일 부터 적용됩니다.</p>
        
      </div>
    </div>
  </div>
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default {
  name:'Policy',
  setup() {
      const siteData = reactive({
      title: `개인정보처리방침 - WithYou 위드유치료교육연구소`,
      description: 'WithYou 위드유치료교육연구소 개인정보처리방침',
      })
      useHead({
          title: computed(() => siteData.title),
          meta: [
                  {
                      name: `description`,
                      content: computed(() => siteData.description),
                  },
          ],
      })
  },
}
</script>
