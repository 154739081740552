<template>
    <div class="PagePost">
        <ul class="PagePost-list">
            <template :key="i" v-for="(post,i) in pagePost">
                <li class="PagePost-item" >
                    <div class="PagePostItem">
                        <template v-if="TemplatePop">
                            <a href="javascript:void(0)" :data-video="post.youtube" @click="OpenPop(i)" class="PagePostItem-link">
                                <div class="PagePostItemThumb youtube">
                                    <img class="PagePostItemThumb-img" :src="'https://img.youtube.com/vi/' + post.youtube + '/maxresdefault.jpg'" alt="" />
                                </div>
                                <div class="PagePostItemCnt">
                                    <div class="PagePostItemCnt-cate" v-if="postType">{{ post.category_name }}</div>
                                    <div class="PagePostItemCnt-title">{{ post.title }}</div>
                                    <div class="PagePostItemCntMeta">
                                        <div class="PagePostItemCntMeta-date">{{ dateTime(post.created_at) }}</div>
                                    </div>
                                </div>
                            </a>
                        </template>
                        <router-link v-else :to="this.querys ? postDomain + post.id + '?keyword=' + this.querys.keyword + '&page=' + this.querys.page : postDomain + post.id" class="PagePostItem-link">
                            <div class="PagePostItemThumb">
                                <img class="PagePostItemThumb-img" v-if="post.thumbnail" :src="post.thumbnail" alt="" />
                                <img class="PagePostItemThumb-img" v-else :src="'/images/view/post/post-thumb.png'" alt="" />
                            </div>
                            <div class="PagePostItemCnt">
                                <div class="PagePostItemCnt-cate" v-if="postDomain == '/About/Activity/'">{{ post.activity_cat }}</div>
                                <div class="PagePostItemCnt-cate" v-if="!postDomain !== '/About/Activity/'&& postType">{{ post.special_cat }}</div>
                                <div class="PagePostItemCnt-title">{{ post.title }}</div>
                                <div class="PagePostItemCntMeta">
                                    <div class="PagePostItemCntMeta-date">{{ dateTime(post.created_at) }}</div>
                                    <div class="PagePostItemCntMeta-state" v-if="postType && post.special_status" :class="post.special_status == '모집중' ? 'Ing':'End'">{{ post.special_status }}</div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </li>
            </template>
            <li v-if="pagePost.length === 0" class="PagePost-item no-Data">데이터가 존재하지 않습니다.</li>
        </ul>
    </div>
    <template v-if="TemplatePop">
        <video-popup :videoUrl="videoUrl" ref="youtube"></video-popup>
    </template>
</template>
<script>
import moment from "moment";
import VideoPopup from "../popup/popup.vue";

export default {
    components: {
      VideoPopup,
    },
    data(){
        return{
            querys :{
                keyword:this.$route.query.keyword?this.$route.query.keyword:'',
                page:this.$route.query.page?this.$route.query.page:'',
            }
        }
    },
    emits: ["videoLink"],
    props:['pagePost', 'postType', 'postDomain', 'TemplatePop', 'videoUrl', 'specialStatus'],
    methods:{
        dateTime(value) {
            return moment(value).format('YYYY.MM.DD');
        },
        OpenPop: function (i) {
            document.getElementsByTagName('html')[0].classList.add('is-Hidden');
            document.getElementsByClassName('VideoPopup')[0].classList.add('is-View');
            this.$emit("videoLink", i);
        },
    }
}
</script>