<template>
    <div class="ProgramPost">
        <ul class="ProgramPost-list">
            <template :key="i" v-for="(post,i) in pagePost">
                <li class="ProgramPost-item" >
                    <div class="ProgramPostItem">
                        <router-link :to="this.querys ? postDomain + post.id + '?page=' + this.querys.page : postDomain + post.id" class="ProgramPostItem-link">
                            <div class="ProgramPostItemThumb">
                                <img class="ProgramPostItemThumb-img" v-if="post.thumbnail" :src="post.thumbnail" alt="" />
                                <img class="ProgramPostItemThumb-img" v-else :src="'/images/view/post/post-thumb.png'" alt="" />
                            </div>
                            <div class="ProgramPostItemCnt">
                                <div class="ProgramPostItemCnt-title">{{post.name}}</div>
                                <ul v-if="postDomain == '/Program/Temperament/'" class="ProgramPostItemCnt-list">
                                    <li class="ProgramPostItemCnt-item">강사 : {{post.teachers}}</li>
                                    <li class="ProgramPostItemCnt-item">평가 시간 : {{post.all_time}}</li>
                                    <li class="ProgramPostItemCnt-item">방문 회차 : {{post.round}}</li>
                                    <li class="ProgramPostItemCnt-item">평가 진행 방식 : {{post.edu_type}}</li>
                                    <li class="ProgramPostItemCnt-item"><span class="ProgramPostItemCnt-bullet">예약 일정 : </span><span class="ProgramPostItemCnt-text">{{post.res_schedule}}</span></li>
                                </ul>
                                <ul v-else class="ProgramPostItemCnt-list">
                                    <li class="ProgramPostItemCnt-item">강사 : {{post.teachers}}</li>
                                    <li class="ProgramPostItemCnt-item">진행 시간 : {{post.all_time}}</li>
                                    <li class="ProgramPostItemCnt-item">진행 회차 : {{post.round}}</li>
                                    <li class="ProgramPostItemCnt-item">교육 진행 방식 : {{post.edu_type}}</li>
                                    <li class="ProgramPostItemCnt-item"><span class="ProgramPostItemCnt-bullet">예약 일정 : </span><span class="ProgramPostItemCnt-text">{{post.res_schedule}}</span></li>
                                </ul>
                            </div>
                        </router-link>
                        <!-- 모집상태 -->
                        <div v-if="btnType == 'Special'||btnType == 'PrivateQualification'||btnType == 'WisdomOfParenting'" class="ProgramPostItemBtn">
                            <div class="PagePostItemCntMeta-state" v-if="post.special_status" :class="post.special_status == '모집중' ? 'Ing':'End'">{{ post.special_status }}</div>
                        </div>
                        <!-- .모집상태 -->
                    </div>
                </li>
            </template>
            <li v-if="pagePost.length === 0" class="ProgramPost-item no-Data">데이터가 존재하지 않습니다.</li>
        </ul>
    </div>
</template>
<script>
export default {
    props:['pagePost','postDomain','btnType'],
    data(){
        return{
            querys :{
                keyword:this.$route.query.keyword?this.$route.query.keyword:'',
                page:this.$route.query.page?this.$route.query.page:'',
            },
        }
    },
    methods:{
        
    }
}
</script>