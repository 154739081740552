<template> 
    <!-- AboutTab -->
    <div class="AboutTab">
        <div class="site-container">    
            <ul class="AboutTab-list">
                <li class="AboutTab-item" :class="[isCurrent === '1' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'Introduction'}" class="AboutTab-link">위드유 소개</router-link>
                </li>
                <li class="AboutTab-item" :class="[isCurrent === '2' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'Researcher'}" class="AboutTab-link">연구원</router-link>
                </li>
                <li class="AboutTab-item" :class="[isCurrent === '3' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'Activity'}" class="AboutTab-link">활동</router-link>
                </li>
                <li class="AboutTab-item" :class="[isCurrent === '4' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'Mou'}" class="AboutTab-link">MOU</router-link>
                </li>
                <li class="AboutTab-item" :class="[isCurrent === '5' ?  'is-Current' : '']">
                    <router-link :to="{ name: 'Notice'}" class="AboutTab-link">위드유 소식</router-link>
                </li>
            </ul>
        </div>
    </div> 
    <!-- .AboutTab -->

</template>
<script>
export default {
    props:['isCurrent']
}
</script>