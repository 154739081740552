<template>
    <top-banner :depthTitle="depthTitle" :imgUrl="imgUrl" :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></top-banner>
    <about-menu :isCurrent='isCurrent'></about-menu>
    <!-- PageAbout -->
    <div class="PageAbout">
        <div class="site-container">    
            <h3 class="PageTitle">위드유 소개</h3>
            <div class="AboutCharacter">
                <div class="AboutCharacterImg">
                    <img src="/images/view/about/img-introduction.png"  class="AboutCharacterImg-img"/>
                </div>
                <div class="AboutCharacterText">
                    <p class="AboutCharacterText-txt">위드유(With You)는 나와 나, 나와 너, 나와 우리라는 관계에서 마음을 연결하고 <br class="br-mobile" />함께 어려움을 해결하며 우리의 모습 속에서 사랑의 선(善)을 회복할 수 있도록 <br class="br-mobile" />함께 한다는 의미를 담고 있습니다.</p>
                </div>
                <download-pdf-button :pdf-url="pdfUrl" :pdf-file-name="pdfFileName" />
            </div>
            <!-- 핵심 가치 -->
            <h3 class="PageTitle">핵심 가치</h3>
            <div class="ValueBox">
                <ul class="ValueBox-list">
                    <!-- item -->
                    <li class="ValueBox-item">
                        <div class="ValueItem">
                            <div class="ValueItemTitle">
                                <div class="ValueItemTitle-inner">
                                    <p class="ValueItemTitle-tit">연결</p>
                                    <p class="ValueItemTitle-txt">connect with you</p>
                                </div>
                            </div>
                            <div class="ValueItemText"><p class="ValueItemText-txt">나와 나, 나와 너의 <br />마음을 연결한다.</p></div>
                        </div>
                    </li>
                    <!-- .item -->
                    <!-- item -->
                    <li class="ValueBox-item">
                        <div class="ValueItem">
                            <div class="ValueItemTitle">
                                <div class="ValueItemTitle-inner">
                                    <p class="ValueItemTitle-tit">해결</p>
                                    <p class="ValueItemTitle-txt">resolve with you </p>
                                </div>
                            </div>
                            <div class="ValueItemText"><p class="ValueItemText-txt">나의 어려움, 나와 너의 <br />어려움을 해결한다.</p></div>
                        </div>
                    </li>
                    <!-- .item -->
                    <!-- item -->
                    <li class="ValueBox-item">
                        <div class="ValueItem">
                            <div class="ValueItemTitle">
                                <div class="ValueItemTitle-inner">
                                    <p class="ValueItemTitle-tit">사랑</p>
                                    <p class="ValueItemTitle-txt">love with you</p>
                                </div>
                            </div>
                            <div class="ValueItemText"><p class="ValueItemText-txt">나를 사랑하고, 너를 <br />사랑하는 관계를 회복한다.</p></div>
                        </div>
                    </li>
                    <!-- .item -->
                </ul>                
            </div>
            <!-- .핵심 가치 -->

            <!-- 비전 -->
            <h3 class="PageTitle">비전</h3>
            <div class="BgAboutBox">
                <div class="BgAboutBoxImg">
                    <img src="/images/view/about/img-about01-min.png"  class="BgAboutBoxImg-img"/>
                </div>
            </div>
            <div class="BgAboutBoxText">
                <p class="BgAboutBoxText-txt">자신과 타인을 바르게 알게 하고, <br />지각 있는 사랑과 지혜를 교육하여 가족의 화목을 도웁니다.</p>
            </div>
            <!-- .비전 -->

            <!-- 연구소장 인사말 -->
            <h3 class="PageTitle">연구소 대표 인사말</h3>
            <div class="Greeting">
                <div class="GreetingText">
                    <p class="GreetingText-txt">
                        위드유치료교육연구소 대표 최은정입니다. <br />저희 연구소를 찾아주셔서 감사합니다. <br />
                        저희 연구소는 한 사람이 가치있는 존재로서 자기다운 모습으로 <br class="br-mobile" />살아갈 수 있도록 심리치료와 교육으로 삶을 지원합니다. <br />
                        아동부터 청소년, 성인까지 우리 모두는 자신이 타고난 기질을 <br class="br-mobile" />세상에서 가치롭게 발현하고자 하는 존재적 동기를 가집니다. <br />
                        자신 뿐 아니라 타인까지도 존재로서 함께 하는 사랑을 회복하도록 동반자가 되어드리겠습니다.<br /> 
                        저희 연구소는 아동에게는 아동심리치료와 양육코칭으로, 청소년들에게는 심리치료, 상담, <br class="br-mobile" />학습코칭으로, 성인에게는 상담 및 라이프 코칭으로 삶을 지원합니다. <br />
                        그 외 기질교육, 민간자격증 양성과정으로 전문가분들의 역량강화를 지원하고 있습니다.<br />
                        온유한 사랑과 지혜로 함께 하겠습니다.
                    </p>
                    <p class="GreetingText-txt">연구소 대표<span class="GreetingText-name">최은정</span></p>
                </div>
                <div class="GreetingImg">
                    <img src="/images/view/about/img-greeting.png" class="GreetingImg-img" />
                </div>
            </div>
            <!-- .연구소장 인사말 -->
        </div>
    </div>
    <!-- .PageAbout -->

</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import TopBanner from "../components/topbanner/topbanner.vue";
import AboutMenu from "../components/aboutmenu/aboutmenu.vue";
import DownloadPdfButton from '../components/pdfdownload/pdfdownload.vue';
export default {
    name:'Intoroduction',
    setup() {
        const siteData = reactive({
        title: `연구소 소개 - WithYou 위드유치료교육연구소`,
        description: 'WithYou 위드유치료교육연구소를 소개합니다.',
        canonical: 'https://withyoulove.co.kr/About/Introduction',
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                    {
                        name: `description`,
                        content: computed(() => siteData.description),
                    },
            ],
            link: [
                {
                    rel: `canonical`,
                    content: computed(() => siteData.canonical),
                },
            ]
        })
    },
    components: {
        TopBanner,
        AboutMenu,
        DownloadPdfButton
    },
    data() {
        return {
        //상단 배너 컨텐츠
            depthTitle:'연구소 소개',
            imgUrl:'../../images/view/topbanner/topbanner-re.png',

            firstTitle:"연구소 소개",
            lastTitle:"소개",
            breadCate:'About',

            //어바웃메뉴
            isCurrent:'1',

            //컨텐츠
            singleCategory:"방학특강",
            singleTitle:"영어동화와 쿠키 만들기",
            singleDate:"2022-01-20",
            singleView:"50",

            //pdf
            pdfUrl: '../../uploads/TalkFile_위드유치료교육연구소-소개.pdf',
            pdfFileName: 'TalkFile_위드유치료교육연구소-소개.pdf'
        };
    },
    mounted() {
        document.body.classList.add('page-about');
    },
    unmounted() {
        document.body.classList.remove('page-about');
    },
}
</script>