<!-- 
 Button Type 
====================================
:button : default
:submit

 Button form 
====================================
:Primary : default
:Secondery
:Danger
:Disabled

 Button Size 
====================================
:Small
:Large
-->

<template>
    <button :type="type" class="Btn" :class="[form, size]" :disabled="disabled">
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        type:{
            type: String,
            default: "button"
        },
        form:{
            type: String,
            default: "Primary"
        },
        size:{
            type: String,
            default: "Small"
        },
        disabled: Boolean
    }
}
</script>
<style lang="scss">
@import './_button.scss';
</style>
