<!-- 
 Heading level
====================================
:h1 -> 1 : default
:h2 -> 2
:h3 -> 3
:h4 -> 4
:h5 -> 5
:h6 -> 6
-->

<template>
    <h1 class="Title" v-if="level === '1'" :class="[type,weight]" :level="level"><slot></slot></h1>
    <h2 class="Title" v-else-if="level === '2'" :class="[type,weight]" :level="level"><slot></slot></h2>
    <h3 class="Title" v-else-if="level === '3'" :class="[type,weight]" :level="level"><slot></slot></h3>
    <h4 class="Title" v-else-if="level === '4'" :class="[type,weight]" :level="level"><slot></slot></h4>
    <h5 class="Title" v-else-if="level === '5'" :class="[type,weight]" :level="level"><slot></slot></h5>
    <h6 class="Title" v-else-if="level === '6'" :class="[type,weight]" :level="level"><slot></slot></h6>
</template>
<script>
export default {
    props: {
        level:{
            type: String,
            default: "1"
        },
        type:{
            type: String,
            default: "Primary"
        },
        weight:{
            type: String,
            default:"Bold"
        },
    }
}
</script>
