<template>
    <top-banner :depthTitle="depthTitle" :imgUrl="imgUrl" :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></top-banner>
    <about-menu :isCurrent='isCurrent'></about-menu>
    <div class="ListSec">
      <div class="site-container">
          <h3 class="PageTitle">위드유 소식</h3>
          <div class="ListFilter">
              <list-search @searchData="getSearchData" id="search"></list-search>
          </div>
          <list-post :pagePost="pagePost" :postType="postType" :postDomain="postDomain" :TemplatePop="TemplatePop"></list-post>
          <list-paging :totalPageCount="totalPageCount" @firstUpdate="firstPage" @itemUpdate="itemPage" @prevUpdate="prevPage" @nextUpdate="nextPage" @lastUpdate="lastPage" :currentPage="currentPage"></list-paging>
      </div>
    </div>
  </template>
  <script>
  import {computed, reactive } from 'vue'
  import { useHead } from '@vueuse/head'
  import TopBanner from "../components/topbanner/topbanner.vue";
  import ListSearch from "../components/filtersearch/filtersearch.vue";
  import ListPost from "../components/post/postnotice.vue";
  import ListPaging from "../components/paging/paging.vue";
  import AboutMenu from "../components/aboutmenu/aboutmenu.vue";

  export default {
    name:'Notice',
    setup() {
        const siteData = reactive({
        title: `위드유 소식 - WithYou 위드유치료교육연구소`,
        description: 'WithYou 위드유치료교육연구소 정보공유를 소개합니다.',
        canonical: 'https://withyoulove.co.kr/About/Notice',
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                    {
                        name: `description`,
                        content: computed(() => siteData.description),
                    },
            ],
            link: [
                {
                    rel: `canonical`,
                    content: computed(() => siteData.canonical),
                },
            ]
        })
    },
    components: {
      TopBanner,
      ListSearch,
      ListPost,
      ListPaging,
      AboutMenu
    },
    data() {
          return {
             //상단 배너 컨텐츠
              depthTitle:'연구소 소개',
              imgUrl:'../../images/view/topbanner/topbanner-info.png',
              firstTitle:"연구소 소개",
              lastTitle:'위드유 소식',
              breadCate:'About',
  
              //리스트
              TemplatePop:false,
              postType:false,
              postDomain:'/About/Notice/',
              pagePost:[],
              moreView:true,
  
              //리스트 세팅 정보
              apiUrl: 'https://withyoulove.co.kr:8000/api/blogList/',
              queryOptions: {
                  per_page: 1,
                  cat:5
              },

               //서브메뉴
               isCurrent:'5',
  
              //검색
              keyword:'',

              //페이징
              totalPageCount:null,
              currentPage:1
          };
      },
      mounted() {
          if(this.$route.query.keyword){
              this.keyword = this.$route.query.keyword;
              this.queryOptions.per_page = 1;
          }

          if(this.$route.query.page){
              this.page = this.$route.query.page;
              this.keyword = this.$route.query.keyword;
              this.queryOptions.per_page = this.page;
          }
          //js/common.js 호출
          this.more('paging');
      },
      methods:{
        itemPage:function(index){
            this.index = index;
            this.getPaging(index);
        },
        firstPage:function(index){
            this.index = index;
            this.index = 1
            this.getPaging(index);
        },
        prevPage:function(index){
            this.index = index;
            this.index--
            this.getPaging(index);
        },
        nextPage:function(index){
            this.index = index;
            this.index++
            this.getPaging(index);
        },
        lastPage:function(index){
            this.index = index;
            this.index = this.totalPageCount
            this.getPaging(index);
        },
      }
  }
  </script>
  