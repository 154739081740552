<template>
    <div v-if="slides.length > 0" class="MultiSlide">
      <swiper 
        :modules="[Controller]"
        @swiper="setFirstSwiper"
        :controller="{ control: secondSwiper }"
        :allowTouchMove="false"
        class="MultiSlideThumb"
      >
        <swiper-slide class="MultiSlideThumb-item" v-for="(slideContent, index) in slides" :key="index" :virtualIndex="index">
            <div class="MultiSlideThumbItem">
              <img class="MultiSlideThumbItem-img" v-if="slideContent.thumbnail" :src="slideContent.thumbnail" alt="" />
              <img class="MultiSlideThumbItem-img" v-else :src="'/images/view/post/post-thumb.png'" alt="" />
            </div>
        </swiper-slide>
        >
      </swiper>
      <swiper
        :modules="[Controller, EffectFade, Navigation]"
        effect="fade"
        @swiper="setSecondSwiper"
        :controller="{ control: firstSwiper }"
        :navigation="true"
        :allowTouchMove="false"
        class="MultiSlideText"
      >
        <swiper-slide class="MultiSlideText-item" v-for="(slideContent, index) in slides" :key="index" :virtualIndex="index">
          <div class="MultiSlideTextItem">
            <div class="MultiSlideTextItem-cate">{{ slideContent.special_cat }}</div>
            <h4 class="MultiSlideTextItem-title">{{ slideContent.title }}</h4>
            <p class="MultiSlideTextItem-sub">{{ slideContent.content_cl}}</p>
            <div class="MultiSlideTextItemBtn">
              <router-link class="MultiSlideTextItemBtn-link" :to="slideDomain + slideContent.id">자세히 보기</router-link>
            </div>
          </div>
        </swiper-slide>
        >
      </swiper>
    </div>
  </template>
  <script>
    import { ref } from 'vue';
    import { Controller, Navigation, EffectFade } from 'swiper';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    
    export default {
      components: {
        Swiper,
        SwiperSlide,
      },
      setup() {
        const firstSwiper = ref(null);
        const secondSwiper = ref(null);
        const setFirstSwiper = (swiper) => {
          firstSwiper.value = swiper;
        };
        const setSecondSwiper = (swiper) => {
          secondSwiper.value = swiper;
        };

        return {
          Controller,
          firstSwiper,
          secondSwiper,
          setFirstSwiper,
          setSecondSwiper,
          Navigation,
          EffectFade,
        };
      },
      props:['slides','slideDomain'],
    };
  </script>