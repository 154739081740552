<template>
    <top-banner :depthTitle="depthTitle" :imgUrl="imgUrl" :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></top-banner>
    <div class="SingleSec">
        <div class="site-container">
            <!-- SingleHeader -->
            <div class="SingleHeader">
                <div class="SingleHeaderTitle">
                    <div class="SingleHeaderTitle-cate">{{ singlePost.category_name }}</div>
                    <h3 class="SingleHeaderTitle-title">{{ singlePost.title }}</h3>
                </div>
                <div class="SingleHeaderMeta">
                    <ul class="SingleHeaderMeta-list">
                        <li class="SingleHeaderMeta-item">{{ dateTime(singlePost.created_at) }}</li>
                        <li class="SingleHeaderMeta-item">조회수 {{ singlePost.hists }}</li>
                    </ul>
                </div>
            </div>
            <!-- .SingleHeader -->
            <!-- SingleCnt -->
            <div class="SingleCnt" v-html="singlePost.content"></div>
            <!-- .SingleCnt -->
            <!-- SingleBtn -->
            <div class="SingleBtn">
                <router-link :to="param" class="SingleBtn-btn">목록보기</router-link>
            </div>
            <!-- .SingleBtn -->
            <!-- SinglePaging -->
            <div class="SinglePaging">
                <ul class="SinglePaging-list">
                    <li class="SinglePaging-item prev">
                        <div v-if="singlePrev.title == ''" class="SinglePaging-link">
                            <span class="SinglePaging-cate">이전글</span>
                            <span class="SinglePaging-title disable">등록된 이전글이 없습니다.</span>
                        </div>
                        <router-link v-else class="SinglePaging-link" :to="prevSlug">
                            <span class="SinglePaging-cate">이전글</span>
                            <span class="SinglePaging-title">{{ singlePrev.title }}</span>
                        </router-link>
                    </li>
                    <li class="SinglePaging-item next">
                        <div v-if="singleNext.title == ''" class="SinglePaging-link">
                            <span class="SinglePaging-cate">다음글</span>
                            <span class="SinglePaging-title disable">등록된 다음글이 없습니다.</span>
                        </div>
                        <router-link v-else class="SinglePaging-link" :to="nextSlug">
                            <span class="SinglePaging-cate">다음글</span>
                            <span class="SinglePaging-title">{{ singleNext.title }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <!-- .SinglePaging -->
        </div>
    </div>
</template>
<script>
import TopBanner from "../components/topbanner/topbanner.vue";
import axios from 'axios';
import moment from "moment";

export default {
    inheritAttrs: false,
    components: {
        TopBanner
    },
    
    data() {
        return {
            //상단 배너 컨텐츠
            depthTitle:'정보공유',
            imgUrl:'../../images/view/topbanner/topbanner-info.png',

            firstTitle:'정보공유',
            lastTitle:[],
            breadCate:'Information',

            //데이터
            postDomain:'',
            singlePost: [],
            singleNext: [],
            singlePrev: [],

            //리스트 세팅 정보
            apiUrl: 'https://withyoulove.co.kr:8000/api/blogView/',
            querys :{
                keyword:this.$route.query.keyword?this.$route.query.keyword:'',
                page:this.$route.query.page?this.$route.query.page:'',
            }
        };
    },
    mounted() {
        axios.get(this.apiUrl + this.$route.params.id,
            {
                params: {
                    page:this.page,
                    keyword:this.keyword,
                }
            }, 
        )
        .then((response) => {
            this.singlePost = response.data.basic_info;
            this.singlePrev = response.data.prev_post;
            this.singleNext = response.data.next_post;
            this.lastTitle = response.data.basic_info.category_name; 
        })
    },
    computed: {
        param: function () {
            return this.querys ? 
            this.singlePost.category_path + '?keyword=' + this.querys.keyword + '&page=' + this.querys.page : 
            this.postDomain + this.singlePost.category_path;
        },
        prevSlug: function () {
            return this.querys ? 
            this.postDomain + this.singlePrev.id + '?keyword=' + this.querys.keyword + '&page=' + this.querys.page : 
            this.postDomain + this.singlePrev.id;
        },
        nextSlug: function () {
            return this.querys ? 
            this.postDomain + this.singleNext.id + '?keyword=' + this.querys.keyword + '&page=' + this.querys.page : 
            this.postDomain + this.singleNext.id;
        }
    },
    methods:{
        dateTime(value) {
            return moment(value).format('YYYY-MM-DD');
        },
    }
}
</script>