<template>
    <div class="VideoPopup">
        <div class="VideoPopupDim"></div>
        <div class="VideoPopupAct">
            <button class="VideoPopupAct-close" @click="closePop" type="button"><span class="ab-text">닫기</span></button>
            <div class="VideoPopupActIframe">
                <youtube-iframe :videoUrl="videoUrl" ref="youtube"></youtube-iframe>
            </div>
        </div>
    </div>
</template>
<script>
import YoutubeIframe from "../youtube/youtube";

export default {
    name:'',
    components: {
        YoutubeIframe
    },
    props:['videoUrl'],
    methods: {
        closePop: function () {
            document.getElementsByTagName('html')[0].classList.remove('is-Hidden');
            document.getElementsByClassName('VideoPopup')[0].classList.remove('is-View');
            document.querySelector('.VideoPopupActIframe-iframe').src='';
        },
    }
}
</script>