<template>About</template>
<script>
export default {
    name:'',
    components: {},
    data() {
        return {
            sampleDate: ''
        };
    },
    beforeCreate() {},
    created() {}, 
    beforeMount() {}, 
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeUnmount(){},
    unmounted(){},
    methods: {}
}
</script>