<template>
    <top-banner :depthTitle="depthTitle" :imgUrl="imgUrl" :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></top-banner>
    <div class="SgProgramSec">
        <div class="small-container">
            <h3 class="PageTitle">{{singlePost.name}}</h3>
            <!-- Header -->
            <div class="SgProgramHeader">
                <div class="SgProgramHeader-item">
                    <!-- SgProgramHeaderInfo -->
                    <div class="SgProgramHeaderAtc">
                        <div class="SgSubTitle" v-if="$route.params.slug == 'Temperament'">평가 정보</div>
                        <div class="SgSubTitle" v-else>수업 정보</div>
                        <div class="SgProgramHeaderAtcList">
                            <ul v-if="$route.params.slug == 'Temperament'" class="SgProgramHeaderAtcList-list">
                                <li class="SgProgramHeaderAtcList-item">
                                    <span class="SgProgramHeaderAtcList-title">평가 방식</span>
                                    <span class="SgProgramHeaderAtcList-text">{{singlePost.edu_type}}</span>
                                </li>
                                <li class="SgProgramHeaderAtcList-item">
                                    <span class="SgProgramHeaderAtcList-title">방문 회차</span>
                                    <span class="SgProgramHeaderAtcList-text">{{singlePost.round}}</span>
                                </li>
                                <li class="SgProgramHeaderAtcList-item">
                                    <span class="SgProgramHeaderAtcList-title">평가 시간</span>
                                    <span class="SgProgramHeaderAtcList-text">{{singlePost.lecture_time}}</span>
                                </li>
                                <li class="SgProgramHeaderAtcList-item">
                                    <span class="SgProgramHeaderAtcList-title">예약 일정</span>
                                    <span class="SgProgramHeaderAtcList-text">{{singlePost.res_schedule}}</span>
                                </li>
                            </ul>
                            <ul v-else class="SgProgramHeaderAtcList-list">
                                <li class="SgProgramHeaderAtcList-item">
                                    <span class="SgProgramHeaderAtcList-title">교육 방식</span>
                                    <span class="SgProgramHeaderAtcList-text">{{singlePost.edu_type}}</span>
                                </li>
                                <li class="SgProgramHeaderAtcList-item">
                                    <span class="SgProgramHeaderAtcList-title">수업 회차</span>
                                    <span class="SgProgramHeaderAtcList-text">{{singlePost.round}}</span>
                                </li>
                                <li class="SgProgramHeaderAtcList-item">
                                    <span class="SgProgramHeaderAtcList-title">강의 시간</span>
                                    <span class="SgProgramHeaderAtcList-text">{{singlePost.lecture_time}}</span>
                                </li>
                                <li class="SgProgramHeaderAtcList-item">
                                    <span class="SgProgramHeaderAtcList-title">예약 일정</span>
                                    <span class="SgProgramHeaderAtcList-text">{{singlePost.res_schedule}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- SgProgramHeaderInfo -->
                </div>
                <div class="SgProgramHeader-item">
                    <!-- SgProgramHeaderTarget -->
                    <div class="SgProgramHeaderAtc">
                        <div v-if="$route.params.slug == 'Temperament'" class="SgSubTitle">평가 대상</div>
                        <div v-else class="SgSubTitle">수강 대상</div>
                        <div class="SgProgramHeaderAtcTarget">
                            <p class="SgProgramHeaderAtcTarget-text" v-html="singlePost.edu_target"></p>
                            <ul class="SgProgramHeaderAtcTarget-list">
                                <li class="SgProgramHeaderAtcTarget-item" v-if="eduChoices.includes('parents') == true && singlePost.edu_target == ''">
                                    <div class="ProgramTarget">
                                        <div class="ProgramTarget-icon">
                                            <img class="ProgramTarget-img" src="/images/view/programdetail/program-target1.png" alt="" />
                                        </div>
                                        <div class="ProgramTarget-title">부모</div>
                                        <div class="ProgramTarget-sub">우리 아이의 <br />기질이 궁금해요</div>
                                    </div>
                                </li>
                                <li class="SgProgramHeaderAtcTarget-item" v-if="eduChoices.includes('teacher') == true && singlePost.edu_target == ''">
                                    <div class="ProgramTarget">
                                        <div class="ProgramTarget-icon">
                                            <img class="ProgramTarget-img" src="/images/view/programdetail/program-target2.png" alt="" />
                                        </div>
                                        <div class="ProgramTarget-title">교사</div>
                                        <div class="ProgramTarget-sub">우리 반 학생의 <br />기질이 궁금해요</div>
                                    </div>
                                </li>
                                <li class="SgProgramHeaderAtcTarget-item" v-if="eduChoices.includes('expert') == true && singlePost.edu_target == ''">
                                    <div class="ProgramTarget">
                                        <div class="ProgramTarget-icon">
                                            <img class="ProgramTarget-img" src="/images/view/programdetail/program-target3.png" alt="" />
                                        </div>
                                        <div class="ProgramTarget-title">전문가</div>
                                        <div class="ProgramTarget-sub">기질분석 전문성을 <br />갖고 싶어요</div>
                                    </div>
                                </li>
                                <li class="SgProgramHeaderAtcTarget-item" v-if="eduChoices.includes('adult') == true && singlePost.edu_target == ''">
                                    <div class="ProgramTarget">
                                        <div class="ProgramTarget-icon">
                                            <img class="ProgramTarget-img" src="/images/view/programdetail/program-target4.png" alt="" />
                                        </div>
                                        <div class="ProgramTarget-title">성인</div>
                                        <div class="ProgramTarget-sub">나의 기질을 <br />이해하고 싶어요</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- SgProgramHeaderTarget -->
                </div>
            </div>
            <!-- .Header -->
            <!-- Precautions -->
            <div class="Precautions">
                <div class="Precautions-title">예약 전 확인사항</div>
                <p class="Precautions-sub" v-html="singlePost.checklist"></p>
            </div>
            <!-- .Precautions -->
            <!-- ProInstructor -->
            <div class="ProInstructor" v-if="reSlides.length > 0">
                <div class="SgSubHeader">
                    <div class="SgSubTitle">강사 정보</div>
                </div>
                <div class="ProInstructorSliderAct" :class="reSlides.length > 2 ? '':'is-Center'">
                    <swiper
                        :slidesPerView="'auto'"
                        :navigation="true"
                        :modules="modules"
                        class="ProInstructorSlider"
                    >
                        <swiper-slide class="ProInstructorSlider-item" v-for="(reSlidesContent, index) in reSlides" :key="index">
                            <div class="ResearcherPostItem">
                                <a href="javascript:void(0)" @click="OpenPop(reSlidesContent.id)" class="ResearcherPostItem-link">
                                    <div class="ResearcherPostItemThumb">
                                        <img class="ResearcherPostItemThumb-img" v-if="reSlidesContent.thumbnail" :src="reSlidesContent.thumbnail" alt="" />
                                        <img class="ResearcherPostItemThumb-img" v-else :src="'/images/view/researcher/research-thumb.png'" alt="" />
                                        <span class="ResearcherPostItemThumbName">
                                            <span class="ResearcherPostItemThumbName-title">{{reSlidesContent.name}}</span>
                                            <span class="ResearcherPostItemThumbName-sub">{{reSlidesContent.position}}</span>
                                        </span>
                                    </div>
                                    <div class="ResearcherPostItemCnt">
                                        <ul class="ResearcherPostItemCnt-list">
                                            <li class="ResearcherPostItemCnt-item" :key="i" v-for="(info,i) in reSlidesContent.info">{{info}}</li>
                                        </ul>
                                    </div>
                                    <div class="ResearcherPostItemLink">상세 약력 보기</div>
                                </a>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <!-- .ProInstructor -->
            <!-- SgProgramInfo -->
            <div class="SgProgramInfo">
                <div class="SgSubHeader">
                    <div v-if="$route.params.slug == 'Temperament'" class="SgSubTitle">평가 상세 정보</div>
                    <div v-else class="SgSubTitle">교육 상세 정보</div>
                    <p class="SgSubTitle-text">{{singlePost.edu_detail_txt}}</p>
                </div>
                <div class="SgProgramInfoBox">
                    <div class="SgProgramInfoList" v-html="singlePost.edu_details"></div>
                </div>
            </div>
            <!-- .SgProgramInfo -->
            <!--ReviewSec -->
            <div v-if="rvSlides.length" class="ReviewSec">
                <div class="SgSubHeader">
                    <div class="SgSubTitle">수강생 후기</div>
                    <p class="SgSubTitle-text">{{singlePost.review_txt}}</p>
                </div>
                <!-- ReviewSlideAct -->
                <div class="ReviewSlideAct">
                    <swiper
                        :slidesPerView="'auto'"
                        :navigation="true"
                        :modules="modules"
                        class="ReviewSlide"
                    >
                        <swiper-slide class="ReviewSlide-item" v-for="(rvSlidesContent, index) in rvSlides" :key="index">
                            <div class="ReviewSlideItem">
                                <div class="ReviewSlideItemStar">
                                    <span class="ReviewSlideItemStarBg"></span>
                                    <span class="ReviewSlideItemStarCheck" :style="'width:' + (rvSlidesContent.rating * 20) + '%'">
                                        <span class="ReviewSlideItemStarCheck-bg"></span>
                                    </span>
                                </div>
                                <div class="ReviewSlideItem-title">{{rvSlidesContent.title}}</div>
                                <p class="ReviewSlideItem-sub">{{rvSlidesContent.content}}</p>
                                <div class="ReviewSlideItem-name">{{rvSlidesContent.name}}</div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <!-- .ReviewSlideAct -->
            </div>
            <!-- .ReviewSec -->
            <!-- PrDetailBtn -->
            <div class="PrDetailBtn">
                <ul class="PrDetailBtn-list">
                    <li v-if="$route.params.slug == 'Special'||$route.params.slug == 'PrivateQualification'||$route.params.slug == 'WisdomOfParenting'" class="PrDetailBtn-item btn-reservation">
                        <a href="#;" class="PrDetailBtn-btn reservation-btn" target="_blank">예약하기</a>
                    </li>
                    <li class="PrDetailBtn-item">
                        <router-link :to="param" class="PrDetailBtn-btn list-btn">목록보기</router-link>
                    </li>
                </ul>
            </div>
            <!-- .PrDetailBtn -->
            <!-- 상담예약 btn -->
            <div v-if="$route.params.slug == 'Temperament'||$route.params.slug == 'Psychotherapy'" class="ResultBtn">
                <p class="ResultBtn-txt">나의 기질에 대해 더 자세히 알고 싶으신가요?</p>
                <a href="tel:0314691028" class="ResultBtn-link">상담예약 031-469-1028</a>
            </div>
            <!-- .상담예약 btn -->
            <!-- 신청하기 btn -->
            <template v-if="submitTarget !== null">
                <!-- div v-if="$route.params.slug == 'Special'||$route.params.slug == 'PrivateQualification'||$route.params.slug == 'WisdomOfParenting'" class="ProgramApplyBtn"> -->
                <div class="ProgramApplyBtn">
                    <a :href="submitTarget" target="_blank" class="ProgramApplyBtn-link">신청하기</a>
                </div>
            </template>
            <!-- .신청하기 btn -->
        </div>
    </div>
    <researcher-pop :contid="contid" :resPost="resPost" :career="career" :education="education" :book="book"></researcher-pop>
</template>
<script>
import TopBanner from "../components/topbanner/topbanner.vue";
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import ResearcherPop from "../components/researcherpop/researcherpop.vue";
import 'swiper/css';

export default {
    inheritAttrs: false,
    components: {
        TopBanner,
        Swiper,
        SwiperSlide,
        ResearcherPop,
    },
    setup() {
      return {
        modules: [Navigation],
      };
    },
    data() {
        return {
            //상단 배너 컨텐츠
            depthTitle:'위드유 프로그램',
            imgUrl:'../../images/view/topbanner/topbanner-pro.png',

            firstTitle:'위드유 프로그램',
            lastTitle:[],
            breadCate:'Program',

            //데이터
            postDomain:'',
            singlePost: [],

            //리스트 세팅 정보
            apiUrl: 'https://withyoulove.co.kr:8000/api/programView/',
            querys :{
                page:this.$route.query.page?this.$route.query.page:'',
            },

            //강사정보
            reSlides:[],
            Thumb:'../images/view/researcher/research-thumb.png',
            contid:'',
            resPost: [],
            career: [],
            education:[],
            book:[],
            popUrl:'https://withyoulove.co.kr:8000/api/teacherView/',

            //리뷰정보
            rvSlides:[],

            //교육대상
            eduChoices:[],
            getParents:false,
            getTeacher:false,
            getExpert:false,
            getAdult:false,
            submitTarget:[],
        };
    },
    mounted() {
        document.body.classList.add('single-program');
        axios.get(this.apiUrl + this.$route.params.id,
            {
                params: {
                    page:this.page,
                }
            }, 
        )
        .then((response) => {
            this.singlePost = response.data.basic_info;
            this.lastTitle = response.data.basic_info.category_name; 
            this.reSlides = response.data.basic_info.teachers;
            this.rvSlides = response.data.basic_info.review;
            this.eduChoices = response.data.basic_info.edu_target_choices;
            this.submitTarget = response.data.basic_info.form_url;
        })
    },
    unmounted() {
        document.body.classList.remove('single-program');
    },
    computed: {
        param: function () {
            return this.querys ? 
            this.singlePost.category_path + '?page=' + this.querys.page : 
            this.postDomain + this.singlePost.category_path;
        },
    },
    methods:{
        OpenPop: function (id) {
            this.contid = id;
            axios.get(this.popUrl + parseInt(this.contid),
                {
                    params: {
                        page:this.page,
                    }
                }, 
            )
            .then((response) => {
                this.resPost = response.data.basic_info;
                this.career = response.data.basic_info.career;
                this.education = response.data.basic_info.education;
                this.book = response.data.basic_info.book;
            })
            document.getElementsByTagName('html')[0].classList.add('is-Hidden');
            document.getElementsByClassName('ResearcherPopup')[0].classList.add('is-View');
        },
    }
}
</script>