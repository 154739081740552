<template>
    <div class="VideoPopupActIframe-act">
        <iframe class="VideoPopupActIframe-iframe" width="560" height="315" :src="'https://www.youtube.com/embed/' + videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
</template>
<script>
export default {
    name:'',
    props:['videoUrl'],
}
</script>