<template>
    <top-banner :depthTitle="depthTitle" :imgUrl="imgUrl" :firstTitle="firstTitle" :lastTitle="lastTitle" :breadCate="breadCate"></top-banner>
    <about-menu :isCurrent='isCurrent'></about-menu>
    <div class="PageMou">
        <div class="site-container">    
            <h3 class="PageTitle">MOU</h3>
            <h3 class="AboutMouTitle pc-hidden">함께하는</h3>
            <!-- TabMou -->
            <slide-tab :slides1="slides1" :slides2="slides2" :slides3="slides3"></slide-tab>
            <!-- .TabMou -->
            <!-- AboutMou -->            
            <div class="AboutMou">
                <!-- 교육현장 치료교육중재 -->
                <div class="AboutMou-item">
                    <h3 class="AboutMouTitle">교육현장 치료교육중재</h3>
                    <div class="AboutMouCont">
                        <ul class="AboutMouCont-list">
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou01.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">교사코칭</h4>
                                        <p class="AboutMouItemCont-txt">발달적 어려움을 보이는 아동의 조기 발견 및 개입을 통한 교육지원</p>
                                    </div>
                                </div>
                            </li>
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou02.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">교사교육</h4>
                                        <p class="AboutMouItemCont-txt">발달적 어려움을 보이는 아동의 조기 발견 및 개입을 통한 교육지원</p>
                                    </div>
                                </div>
                            </li>
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou03.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">TEP프로그램</h4>
                                        <p class="AboutMouItemCont-txt">Therapy & Education program<br />심리치료와 교육중재를 개별지원</p>
                                    </div>
                                </div>
                            </li>
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou04.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">정서중심코칭</h4>
                                        <p class="AboutMouItemCont-txt">아동의 사회정서발달촉진을 위한 교사의 사회정서행동코칭</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- .교육현장 치료교육중재 -->
                <!-- Good Enough Teacher -->
                <div class="AboutMou-item">
                    <h3 class="AboutMouTitle">Good Enough Teacher</h3>
                    <div class="AboutMouCont">
                        <ul class="AboutMouCont-list">
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou05.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">교사심리지원프로그램</h4>
                                        <p class="AboutMouItemCont-txt">교사의 정서소진과 회복탄력성 회복<br />교사의 자기이해를 통한 역할과 가치발견</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- .Good Enough Teacher -->
                <!-- 위드유 전문가연수 및 워크숍 -->
                <div class="AboutMou-item">
                    <h3 class="AboutMouTitle">위드유 전문가연수 및 워크숍</h3>
                    <div class="AboutMouCont">
                        <ul class="AboutMouCont-list">
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou06.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">교육심리이론교육</h4>
                                    </div>
                                </div>
                            </li>
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou07.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">교육~설계 퍼실리테이션</h4>
                                    </div>
                                </div>
                            </li>
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou08.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">기질검사와 교수개입</h4>
                                        <p class="AboutMouItemCont-txt">아이들의 기질특성과 정서행동문제해결하기</p>
                                        
                                    </div>
                                </div>
                            </li>
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou09.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">사회정서행동 교수전략</h4>
                                        <p class="AboutMouItemCont-txt">교실에서의 사례를 함께 해결해보는 워크숍 </p>
                                    </div>
                                </div>
                            </li>
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou10.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">아동전문가 대화교육</h4>
                                    </div>
                                </div>
                            </li>
                            <li class="AboutMouCont-item">
                                <div class="AboutMouItem">
                                    <div class="AboutMouItemImg"><img src="/images/view/about/img-mou11.png" /></div>
                                    <div class="AboutMouItemCont">
                                        <h4 class="AboutMouItemCont-tit">아동전문가 수퍼비젼</h4>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- .위드유 전문가연수 및 워크숍 -->
            </div>
            <!-- .AboutMou -->
        </div>
    </div>
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import TopBanner from "../components/topbanner/topbanner.vue";
import AboutMenu from "../components/aboutmenu/aboutmenu.vue";
import SlideTab from "../components/slidetab/slidetab.vue";

export default {
    name:'Mou',
    setup() {
        const siteData = reactive({
        title: `MOU - WithYou 위드유치료교육연구소`,
        description: 'WithYou 위드유치료교육연구소 MOU을 소개합니다.',
        canonical: 'https://withyoulove.co.kr/About/Mou',
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                    {
                        name: `description`,
                        content: computed(() => siteData.description),
                    },
            ],
            link: [
                {
                    rel: `canonical`,
                    content: computed(() => siteData.canonical),
                },
            ]
        })
    },
    components: {
        TopBanner,
        AboutMenu,
        SlideTab
    },
    data() {
        return {
        //상단 배너 컨텐츠
            depthTitle:'연구소 소개',
            imgUrl:'../../images/view/topbanner/topbanner-re.png',
            firstTitle:"연구소 소개",
            lastTitle:'MOU',
            breadCate:'About',

            //어바웃메뉴
            isCurrent:'4',

            //슬라이드
            slides1:[],
            slides2:[],
            slides3:[],
            apiSlideMou1:'https://withyoulove.co.kr:8000/api/slideList/1',
            apiSlideMou2:'https://withyoulove.co.kr:8000/api/slideList/2',
            apiSlideMou3:'https://withyoulove.co.kr:8000/api/slideList/4',
        };
    },
    mounted() {
        this.mouSlideMore('1');
        this.mouSlideMore('2');
        this.mouSlideMore('3');
    },
}
</script>