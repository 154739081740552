<template>
    <div class="ListSearch">
        <input type="text" class="ListSearch-text" placeholder="검색어를 입력하세요" v-model="searchData" id="search-bar"  @keypress.enter="onInput()">
        <button type="submit" class="ListSearch-submit" @click="onInput()"><span class="ab-text">검색하기</span></button>
    </div>
</template>
<script>
export default {
    name:'',
    data() {
        return {
            searchData: this.$route.query.keyword
        };
    },
    methods: {
        onInput() {
            this.$emit("searchData", this.searchData);
            document.getElementById("search-bar").value = ""; 
        }
    }
}
</script>