<template>
  <h2>tt</h2>
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default {
  name:'Terms',
  setup() {
      const siteData = reactive({
      title: `이용약관 - WithYou 위드유치료교육연구소`,
      description: 'WithYou 위드유치료교육연구소의 이용약관',
      })
      useHead({
          title: computed(() => siteData.title),
          meta: [
                  {
                      name: `description`,
                      content: computed(() => siteData.description),
                  },
          ],
      })
  },
}
</script>
