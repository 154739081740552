import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue' //메인
import About from '../views/About.vue' //1뎁스
import Introduction from '../views/Introduction.vue' //2뎁스
import Program from '../views/Program.vue' //1뎁스
import Temperament from '../views/Temperament.vue' //1뎁스
import Psychotherapy from '../views/Psychotherapy.vue' //1뎁스
import Special from '../views/Special.vue' //1뎁스
import PrivateQualification from '../views/PrivateQualification.vue' //1뎁스
import WisdomOfParenting from '../views/WisdomOfParenting.vue' //1뎁스
import Information from '../views/Information.vue' //1뎁스
import Notice from '../views/Notice.vue' //1뎁스
import SpecialLecture from '../views/SpecialLecture.vue' //1뎁스
import Wisdom from '../views/Wisdom.vue' //1뎁스
import Support from '../views/Support.vue' //1뎁스
import Contact from '../views/Contact.vue' //1뎁스
import Location from '../views/Location.vue' //Location
import Store from '../views/Store.vue' //1뎁스
import Policy from '../views/Policy.vue' //1뎁스
import Terms from '../views/Terms.vue' //1뎁스
import Activity from '../views/Activity.vue' //1뎁스
import Mou from '../views/Mou.vue' //1뎁스
import Researcher from '../views/Researcher.vue' //1뎁스
import Detail from '../views/Detail.vue' //Detail
import ActivityDetail from '../views/ActivityDetail.vue' //Detail
import ProgramDetail from '../views/ProgramDetail.vue' //프로그램Detail
import TestIntro from '../views/TestIntro.vue' //TestIntro
import UnderTest from '../views/UnderTest.vue' //UnderTest
import TestResult from '../views/TestResult.vue' //TestResult
import NotFound from '../views/NotFound.vue' //NotFound


const router = createRouter({
  mode: 'history',

  scrollBehavior() {
    return window.scrollTo(0, 0)
  },
  
  history: createWebHistory(process.env.BASE_URL),
  routes : [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/About',
      name: 'About',
      component: About,
      meta: { 
        hideNavigation: true 
      }
    },
    {
      path: '/About/Introduction',
      name: 'Introduction',
      component: Introduction,
    },
    {
      path: '/About/Activity',
      name: 'Activity',
      component: Activity,
    },
    {
      path: '/About/Mou',
      name: 'Mou',
      component: Mou,
    },
    {
      path: '/About/Researcher',
      name: 'Researcher',
      component: Researcher,
    },
    {
      path: '/Program',
      name: 'Program',
      component: Program,
      meta: { 
        hideNavigation: true 
      }
    },
    {
      path: '/Program/Temperament',
      name: 'Temperament',
      component: Temperament,
    },
    {
      path: '/Program/Psychotherapy',
      name: 'Psychotherapy',
      component: Psychotherapy,
    },
    {
      path: '/Program/Special',
      name: 'Special',
      component: Special,
    },
    {
      path: '/Program/PrivateQualification',
      name: 'PrivateQualification',
      component: PrivateQualification,
    },
    {
      path: '/Program/WisdomOfParenting',
      name: 'WisdomOfParenting',
      component: WisdomOfParenting,
    },
    {
      path: '/Information',
      name: 'Information',
      component: Information,
    },
    {
      path: '/About/Notice',
      name: 'Notice',
      component: Notice,
    },
    {
      path: '/Information/SpecialLecture',
      name: 'SpecialLecture',
      component: SpecialLecture,
    },
    {
      path: '/Information/Wisdom',
      name: 'Wisdom',
      component: Wisdom,
    },
    {
      path: '/Support',
      name: 'Support',
      component: Support,
    },
    {
      path: '/Support/Contact',
      name: 'Contact',
      component: Contact,
    },
    {
      path: '/Support/location',
      name: 'Location',
      component: Location,
    },
    {
      path: '/Store',
      name: 'Store',
      component: Store,
    },
    {
      path: '/Policy',
      name: 'Policy',
      component: Policy,
    },
    {
      path: '/Terms',
      name: 'Terms',
      component: Terms,
    },
    {
      path: '/testintro',
      name: 'TestIntro',
      component: TestIntro,
      meta: { 
        hideNavigation: true 
      }
    },
    {
      path: '/UnderTest',
      name: 'UnderTest',
      component: UnderTest,
      meta: { 
        hideNavigation: true 
      }
    },
    {
      path: '/TestResult/:id',
      name: 'TestResult',
      component: TestResult,
      meta: { 
        hideNavigation: true 
      }
    },
    {
      path: "/Information/:slug/:id",
      name: "Detail",
      component: Detail,
      props: true,
    },
    {
      path: "/About/:slug/:id",
      name: "ActivityDetail",
      component: ActivityDetail,
      props: true,
    },
    {
      path: "/Program/:slug/:id",
      name: "ProgramDetail",
      component: ProgramDetail,
      props: true,
    },
    {
      path: "/404",
      name: "NotFound",
      component: NotFound,
      meta: { 
        hideNavigation: true 
      }
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
    },
  ]
})

export default router
