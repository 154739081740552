<template>
  <h2>tt</h2>
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default {
  name:'Program',
  setup() {
      const siteData = reactive({
      title: `위드유 프로그램 - WithYou 위드유치료교육연구소`,
      description: 'WithYou 위드유치료교육연구소 프로그램을 소개합니다.',
      })
      useHead({
          title: computed(() => siteData.title),
          meta: [
                  {
                      name: `description`,
                      content: computed(() => siteData.description),
                  },
          ],
      })
  },
}
</script>
