<template>
  <div class="site" :class="!$route.meta.hideNavigation ? '':'non-Footer'">
    <router-link class="Floting" :to="{name: 'TestIntro'}"><img class="Floting-img" src="/images/view/main/floting_img.png" alt="" /></router-link>
    <!-- Header -->
    <site-header></site-header>
    <!-- .Header -->
    <!-- Body -->
    <div class="site-content">
        <router-view :key="$route.fullPath" />
    </div>
    <!-- .Body -->
    <!-- Footer -->
    <site-footer></site-footer>
    <!-- .Footer -->
  </div>
</template>
<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import SiteHeader from '@/assets/theme/layout/header';
import SiteFooter from '@/assets/theme/layout/footer';

export default {
    setup() {
        const siteData = reactive({
            title: `WithYou 치료교육연구소`,
            description: `STA 기질검사는 나만의 타고난 기질을 발견하는 검사입니다.`,
            keywords: `위드유치료교육연구소, STA, 기질검사, withyou, withyoulove`,
            url:`https://withyoulove.co.kr`,
            img:`https://withyoulove.co.kr/images/logo/favicon_v1.png`,
            size256:`https://withyoulove.co.kr/images/logo/favicon_256.png`,
            size192:`https://withyoulove.co.kr/images/logo/favicon_192.png`,
            size180:`https://withyoulove.co.kr/images/logo/favicon_180.png`,
            size16:`https://withyoulove.co.kr/images/logo/favicon_16.png`,
            size32:`https://withyoulove.co.kr/images/logo/favicon_32.png`,
        })
        useHead({
            title: computed(() => siteData.title),
            meta: [
                    {
                        name: `description`,
                        content: computed(() => siteData.description),
                    },
                    {
                        name: `keywords`,
                        content: computed(() => siteData.keywords),
                    },
                    {
                        property: `og:title`,
                        content: computed(() => siteData.title),
                    },
                    {
                        property: `og:url`,
                        content: computed(() => siteData.description),
                    },
                    {
                        property: `og:image`,
                        content: 'https://withyoulove.co.kr/images/logo/favicon_v1.png',
                    },
                    {
                        property: `og:description`,
                        content: computed(() => siteData.description),
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:title`,
                        content: computed(() => siteData.title),
                    },
                    {
                        name: `twitter:url`,
                        content: computed(() => siteData.url),
                    },
                    {
                        name: `twitter:image`,
                        content: 'https://withyoulove.co.kr/images/logo/favicon_v1.png',
                    },
                    {
                        name: `twitter:description`,
                        content: computed(() => siteData.description),
                    },
                    {
                        rel: `apple-touch-icon`,
                        sizes: `180x180`,
                        href: computed(() => siteData.size180),
                    },
                    {
                        rel: `icon`,
                        type: `image/png`,
                        sizes: `256x256`,
                        href: computed(() => siteData.size192),
                    },
                    {
                        rel: `icon`,
                        type: `image/png`,
                        sizes: `192x192`,
                        href: computed(() => siteData.size256),
                    },
                    {
                        rel: `icon`,
                        type: `image/png`,
                        sizes: `16x16`,
                        href: computed(() => siteData.size16),
                    },
                    {
                        rel: `icon`,
                        type: `image/png`,
                        sizes: `32x32`,
                        href: computed(() => siteData.size32),
                    },
            ],
        })
    },
    components: {
        SiteHeader,
        SiteFooter
    },
}
</script>
