<template>
    <footer class="site-footer">
        <button type="button" class="TopButton" @click="TopButton()">
            <span class="TopButton-text">TOP</span>
        </button>
        <div class="site-container">
            <div class="FooterSec">
                <!-- FooterSecSns -->
                <div class="FooterSecSns">
                    <ul class="FooterSecSns-list">
                        <li class="FooterSecSns-item">
                            <a href="https://www.instagram.com/withyoulove1225/?igshid=YmMyMTA2M2Y%3D" class="FooterSecSns-link instargram" target="_blank"><span class="ab-text">instargram</span></a>
                        </li>
                        <li class="FooterSecSns-item">
                            <a href="https://www.facebook.com/eunjung.choi.100046?mibextid=LQQJ4d" class="FooterSecSns-link facebook" target="_blank"><span class="ab-text">facebook</span></a>
                        </li>
                    </ul>
                </div>
                <!-- .FooterSecSns -->
                <!-- FooterSecMeta -->
                <div class="FooterSecMeta">
                    <div class="FooterLogo">
                        <span class="ab-text">위드유</span>
                    </div>
                    <div class="FooterInfo">
                        <div class="FooterInfoNav">
                            <ul class="FooterInfoNav-list">
                                <li class="FooterInfoNav-item">
                                    <router-link class="FooterInfoNav-link text-Decoration" to="/Policy">개인정보처리방침</router-link>
                                </li>
                                <li class="FooterInfoNav-item">
                                    <router-link class="FooterInfoNav-link" to="/Support/location">오시는길</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="FooterAddress">
                            <p class="FooterAddress-item">경기도 안양시 만안구 안양로 336 6층 위드유치료교육연구소</p>
                            <p class="FooterAddress-item"><a href="tel:0314691028">031-469-1028</a></p>
                        </div>
                        <div class="FooterCopy">© 2023 WithYou치료교육연구소, Inc. All rights reserved.</div>
                    </div>
                </div>
                <!-- .FooterSecMeta -->
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name:'',
    components: {},
    data() {
        return {
            sampleDate: ''
        };
    },
    mounted() {
        this.topFix();
        this.getScrollTopBtn();
    },
    methods: {
        TopButton(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            })
        },
        topFix(){
            var documentHeight = window.outerHeight;
            window.addEventListener('scroll',function() {
                if (document.scrollingElement.scrollTop > documentHeight - 20) {
                    document.getElementsByClassName('TopButton')[0].classList.add('is-Open');
                }else{
                    document.getElementsByClassName('TopButton')[0].classList.remove('is-Open');
                }
            });
        },
        getScrollTopBtn: function(){
            function scrollTopBtn() {
                const _trigger = document.getElementsByClassName('site-footer');
                const item = document.getElementsByClassName('TopButton')[0];
                const flot = document.getElementsByClassName('Floting')[0];
                const _winHeigth = window.innerHeight;
                const _scroll = window.scrollY + _winHeigth;
                const _offSetTop = _trigger[0].offsetTop; 
                const scTop = document.scrollingElement.scrollTop;
                
                //btn View
                if (scTop > _winHeigth){
                    item.classList.add('is-View');
                    flot.classList.add('is-View');
                }else{
                    item.classList.remove('is-View');
                    flot.classList.remove('is-View');
                }
                
                //btn Stop
                if (_scroll > _offSetTop) {
                    item.classList.add('is-Stop');
                }else{
                    item.classList.remove('is-Stop');
                }
            }

            //실행
            scrollTopBtn();

            window.addEventListener('scroll',function() {
                scrollTopBtn();
            });

            window.addEventListener('resize',function() {
                scrollTopBtn();
            });
        }
    }
}
</script>