<template>
    <div v-if="showPopup" class="EventPopup">
       <div class="EventPopupDim"></div>
       <div class="EventPopupFlex">
            <swiper
                :slides-per-view="1"
                :space-between="0"
                :pagination="{
                    clickable:true
                }"
                navigation
                :modules="modules"
                class="EventSwiper"
                >
                <swiper-slide v-for="(slide, index) in slides" :key="index">
                    <a class="EventSwiper-link" href="https://forms.gle/o8AFtiLcGJ9Dw62h8" target="_blank"><img :src="slide" alt="slide image" /></a>
                </swiper-slide>
            </swiper>
            <div class="EventPopupMeta">
                <button class="EventPopupStorage" @click="closePopup">하루동안 안보기</button>
                <button class="EventPopup-close" @click="closeTemporarily">닫기</button>
            </div>
       </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';

export default {
    name:'',
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
      return {
        modules: [Pagination, Navigation],
      };
    },
    data() {
        return {
            showPopup: true,
            slides: [
                'images/view/main/event_001.png',
                'images/view/main/event_002.png',
                'images/view/main/event_003.png',
                'images/view/main/event_004.png',
                'images/view/main/event_005.png',
                'images/view/main/event_006.png'
            ]
        };
    },
    mounted() {
        this.checkPopup();
    },

    methods: {
       checkPopup() {
            const notShowPopupUntil = localStorage.getItem("notShowPopupUntil");
            const currentTime = new Date().getTime();

            // 저장된 시간이 있고, 현재 시간보다 작으면 팝업을 안보이도록
            if (notShowPopupUntil && currentTime < notShowPopupUntil) {
                this.showPopup = false;
            } else {
                this.showPopup = true;
                document.documentElement.classList.add("is-Hidden");
            }
        },
         closePopup() {
            const oneDayLater = new Date().getTime() + 24 * 60 * 60 * 1000;
            localStorage.setItem("notShowPopupUntil", oneDayLater);

            // 팝업 닫기
            this.showPopup = false;
            document.documentElement.classList.remove("is-Hidden");
            },
            closeTemporarily() {

            // 그냥 팝업만 닫기
            this.showPopup = false;
            document.documentElement.classList.remove("is-Hidden");
        }
    }
}
</script>